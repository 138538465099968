:root {
  --lighter-blue: #4d6577;
  --white: white;
  --turqoise: #66b6bf;
  --dark-blue: #334451;
  --light-mint: #cfe0e1;
  --light-cream: #f9f7f7;
  --parliament-1: #334451;
  --light-purple: #495a69;
  --peach-color: #d4735c;
  --yellow: #f1a647;
  --sand: #b5ad9c;
  --medium-cream: #e4dbcc;
  --super-light: #7a8c9b;
  --green: #81be70;
  --black: black;
}

.w-layout-blockcontainer {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.w-checkbox {
  margin-bottom: 5px;
  padding-left: 20px;
  display: block;
}

.w-checkbox:before {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox:after {
  content: " ";
  clear: both;
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox-input {
  float: left;
  margin: 4px 0 0 -20px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border: 1px solid #ccc;
  border-radius: 2px;
  width: 12px;
  height: 12px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  background-image: url('/images/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: #3898ec;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

@media screen and (max-width: 991px) {
  .w-layout-blockcontainer {
    max-width: 728px;
  }
}

@media screen and (max-width: 767px) {
  .w-layout-blockcontainer {
    max-width: none;
  }
}

body {
  color: #333;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 20px;
}

.navbar {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: #fff3;
  border-bottom: 1px solid #fff;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-decoration: none;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.navbar-container {
  flex: 1;
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  padding: 10px 5vw;
  display: flex;
}

.navbar-home {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  cursor: pointer;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 8px;
  transition: all .2s cubic-bezier(.39, .575, .565, 1);
  display: flex;
}

.navbar-home:hover {
  transform: scale(1.03);
}

.navbar-home.w--current {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  display: flex;
}

.navbar-logo {
  object-fit: cover;
  justify-content: center;
  align-items: center;
  height: auto;
  text-decoration: none;
  display: flex;
}

.investment-office-logo {
  border-left: 1px solid #334451;
  height: 45px;
  padding-left: 20px;
  padding-right: 20px;
}

.navlinks {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.nav-button {
  color: #334451;
  cursor: pointer;
  margin: auto 0;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 800;
  line-height: 28px;
  text-decoration: none;
  transition: all .2s cubic-bezier(.86, 0, .07, 1);
}

.nav-button:hover {
  color: #4d6577;
  transform: scale(1.05);
}

.nav-button.w--current {
  color: var(--lighter-blue);
}

.heading-1 {
  color: var(--dark-blue);
  letter-spacing: -.04em;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 60px;
  font-weight: 700;
  line-height: 72px;
  text-decoration: none;
}

.heading-1.light {
  color: var(--white);
}

.hero-content-div {
  align-items: stretch;
  display: flex;
}

.description {
  z-index: 1;
  color: var(--lighter-blue);
  letter-spacing: -.02em;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  text-decoration: none;
  position: relative;
}

.description.white {
  color: var(--white);
}

.description.white.mini {
  z-index: 0;
  text-shadow: 1px 1px 4px rgb(51 68 82);
}

.description.mini {
  z-index: 1;
  font-size: 15px;
  line-height: 24px;
  position: relative;
}

.tagline-heading {
  color: var(--turqoise);
  letter-spacing: .08em;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-decoration: none;
}

.tagline-heading.light {
  color: var(--white);
}

.hero-section {
  background-color: #fff4e4;
  background-image: radial-gradient(circle at 100% 0, #87a4a9, #d3dfdf00 37%), linear-gradient(#cedfe1, #d8e4e3);
  position: relative;
}

.container {
  flex: 1;
  align-items: center;
  max-width: 1200px;
  display: block;
}

.heading-set-3 {
  grid-row-gap: 24px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  text-decoration: none;
  display: flex;
}

.heading-set-3.middle {
  justify-content: flex-start;
  align-items: center;
}

.limited-heading-set {
  grid-row-gap: 8px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 648px;
  text-decoration: none;
  display: flex;
}

.heading-set {
  z-index: 3;
  grid-row-gap: 40px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
  display: flex;
  position: relative;
}

.heading-set.middle {
  align-self: center;
}

.body {
  background-color: #cfe0e1;
  font-family: Inter, sans-serif;
}

.cover-image {
  z-index: 1;
  position: relative;
}

.cover-image.absolute {
  z-index: 102;
  position: absolute;
  inset: -245px 30px auto auto;
}

.cover-image.absolute.modal {
  top: -119px;
}

.cover-image.insights {
  top: 160px;
}

.cover-visual {
  width: 480px;
  height: 480px;
}

.h3-heading {
  color: var(--dark-blue);
  letter-spacing: -.04em;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 40px;
  line-height: 1.2;
}

.h3-heading.middle {
  text-align: center;
}

.h3-heading.middle.light {
  color: var(--white);
}

.content-block {
  background-color: #fafafa;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 488px;
  min-height: 488px;
  padding: 32px;
  display: flex;
}

.icon-regular-2 {
  flex: none;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  transition: color .2s;
  display: flex;
}

.content-feature-wrap {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.content-feature-wrap.full {
  justify-content: space-between;
  align-items: flex-start;
}

.content-feature-wrap.full.grid {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  background-color: var(--lighter-blue);
  border-radius: 12px;
  grid-template-rows: auto;
  grid-template-columns: 40px 4fr;
  grid-auto-columns: 1fr;
  place-items: center start;
  padding: 10px;
  display: grid;
}

.bold-text {
  color: var(--dark-blue);
}

.bold-text.light {
  color: var(--white);
}

.content-feature-grid {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
}

.section-large {
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 120px 5%;
  display: flex;
  position: relative;
}

.section-large.dark-blue {
  background-color: var(--dark-blue);
  padding-top: 60px;
  padding-bottom: 60px;
}

.section-large.infographics {
  background-image: radial-gradient(circle farthest-corner at 0% 50%, var(--light-cream), #f9f7f700), linear-gradient(180deg, var(--light-cream), #f9f7f736 16%, #f9f7f700 53%, var(--light-cream)), url('/images/FDI-Strategy-Design-Guideline-Map_1.webp'), linear-gradient(to bottom, var(--light-cream), var(--light-cream));
  background-position: 0 0, 0 0, 100% 100%, 0 0;
  background-repeat: repeat, repeat, no-repeat, repeat;
  background-size: auto, auto, 80%, auto;
  background-attachment: scroll, scroll, fixed, scroll;
  padding: 80px 5%;
}

.section-large.turqoise {
  background-color: var(--turqoise);
  grid-template-rows: 1fr 1fr 1fr 1fr;
}

.paragraph-small {
  color: var(--lighter-blue);
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
}

.paragraph-small.light {
  color: var(--white);
}

.tagline-heading-2 {
  color: var(--light-cream);
  letter-spacing: .08em;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-decoration: none;
}

.tagline-heading-2.turqoise {
  color: var(--turqoise);
}

.content-wrapper {
  grid-column-gap: 32px;
  grid-row-gap: 40px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.content-grid-b {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.wrap-v-x-small {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-direction: column;
  display: flex;
}

.container-large-2 {
  z-index: 5;
  flex-flow: column;
  width: 100%;
  max-width: 1216px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: relative;
}

.paragraph-large-2 {
  letter-spacing: -.02em;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 24px;
}

.paragraph-large-2.text-weight-medium {
  font-weight: 500;
}

.learn_wrapper {
  padding-right: 40px;
  position: sticky;
  top: 34%;
}

.col-left {
  flex: 1;
  align-self: stretch;
  position: relative;
}

.block-title {
  opacity: .2;
  letter-spacing: -.04px;
  font-size: 4rem;
  font-weight: 900;
  line-height: 1;
}

.block-title.white {
  opacity: 1;
}

.block-title.white.large {
  font-size: 6rem;
}

.section {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  padding-top: 140px;
  padding-bottom: 160px;
  position: relative;
}

.block {
  background-color: var(--dark-blue);
  color: #fff;
  border-radius: 40px;
  align-items: stretch;
  min-height: 400px;
  margin-bottom: 100px;
  padding: 48px 50px 56px;
  font-size: 28px;
  font-weight: 300;
  line-height: 1.4;
  display: flex;
  position: sticky;
}

.block._3 {
  background-color: #618ba0;
  top: 30%;
}

.block._2 {
  background-color: #446372;
  top: 26%;
}

.block._1 {
  background-color: var(--parliament-1);
  justify-content: flex-start;
  align-items: stretch;
  top: 22%;
}

.container-2 {
  width: 90%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.container-2.cc-flex-h {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.col-right {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.div-block {
  flex-flow: column;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.div-2columns {
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  display: flex;
}

.smaller-span {
  font-size: 2.5rem;
}

.index__item {
  cursor: pointer;
  background-color: #fff;
  border-radius: .25rem;
  align-items: center;
  height: 6.25rem;
  margin-bottom: .75rem;
  padding: 1rem 1.5rem;
  font-weight: 700;
  transition: box-shadow .4s;
  display: flex;
  position: relative;
  box-shadow: 0 10px 20px #74675b0f;
}

.index__item:hover {
  box-shadow: 0 16px 64px #74675b61;
}

.feature-div {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-flow: column;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
}

.full-icon {
  flex: none;
  justify-content: center;
  align-items: center;
  height: 40px;
  transition: color .2s;
  display: flex;
}

.h4-heading {
  z-index: 1;
  color: var(--dark-blue);
  letter-spacing: -.04em;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.2;
  position: relative;
}

.h4-heading.h4-read-more {
  font-size: 24px;
}

.h4-heading.light {
  color: var(--white);
}

.h4-heading.turqoise {
  color: var(--turqoise);
}

.middle-heading-set {
  grid-row-gap: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 648px;
  text-decoration: none;
  display: flex;
}

.dot-block {
  justify-content: center;
  align-self: flex-end;
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
  position: relative;
}

.step-content-block {
  text-align: left;
  align-self: center;
  position: relative;
}

.trigger {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0% 0% auto auto;
}

.trigger._3 {
  height: 29%;
  top: 71%;
}

.trigger._1 {
  height: 85%;
  top: 15%;
}

.trigger._2 {
  height: 59%;
  top: 41%;
}

.scroll-line-wrap {
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  position: absolute;
  inset: auto auto 0% -50%;
}

.scroll-container {
  width: 100%;
  height: 100vh;
  position: sticky;
  top: 0;
  overflow: hidden;
}

.step-scroll {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.bg-shadow {
  z-index: 1;
  border-radius: 16px;
  position: absolute;
  inset: 0%;
  box-shadow: 7px 7px 20px #12121212;
}

.scroll-wrap {
  align-self: flex-start;
  width: 100%;
  height: 400vh;
  display: flex;
  position: relative;
}

.step {
  z-index: 2;
  background-color: #fff;
  border-radius: 16px;
  align-items: center;
  padding: 12%;
  display: flex;
  position: relative;
}

.content-wrap {
  justify-content: flex-end;
  width: 40%;
  display: flex;
}

.img-block {
  justify-content: center;
  align-self: center;
  align-items: center;
  width: 40%;
  display: flex;
}

.scroll-animate {
  z-index: 999;
  transform-origin: 0%;
  background-color: #006cd0;
  width: 100%;
  height: 6px;
  position: absolute;
  bottom: 0;
}

.scroll-base {
  transform-origin: 0%;
  background-color: #eeeff4;
  width: 100%;
  height: 6px;
}

.clone-section {
  margin-bottom: 50vh;
}

.dot {
  z-index: 3;
  background-color: #eeeff4;
  border-radius: 50%;
  align-self: center;
  width: 12px;
  height: 12px;
  position: relative;
}

.label {
  color: #006cd0;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 700;
}

.content {
  margin-top: 24px;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.4;
}

.step-block-2 {
  flex-wrap: nowrap;
  flex: none;
  place-content: center;
  align-items: center;
  width: 100vw;
  height: 80vh;
  display: flex;
  position: relative;
}

.heading-2 {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
}

.timeline__year {
  flex: none;
  font-size: 202px;
  line-height: 1em;
  transition: all .5s;
}

.timeline__txt-block {
  padding-bottom: 1px;
}

.section-2 {
  padding: 64px;
}

.section-2.section--timeline {
  min-height: 280vh;
}

.timeline__ico-title {
  letter-spacing: .05em;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.2em;
}

.timeline__lines-wrap {
  width: 100%;
  height: 1px;
  margin-top: -20px;
  margin-bottom: -20px;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  overflow: hidden;
}

.btn-tag-2 {
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #101010;
  border-radius: 100px;
  flex: none;
  justify-content: center;
  align-items: center;
  min-width: 110px;
  margin-right: 16px;
  padding: 4px 20px 5px 16px;
  font-weight: 500;
  transition: all .2s;
  display: flex;
}

.btn-tag-2:hover,
.btn-tag-2.w--current {
  color: #e1d4d5;
  background-color: #101010;
}

.timeline__col {
  flex: 1;
  justify-content: flex-end;
}

.timeline__col.mod--2 {
  justify-content: flex-start;
  width: 100%;
  max-width: 700px;
  margin-top: -6px;
}

.timeline__col.mod--4 {
  justify-content: flex-start;
  width: 100%;
  max-width: 790px;
}

.timeline__col.mod--1 {
  flex: 0 auto;
  justify-content: flex-start;
}

.timeline__line2 {
  background-color: #101010;
  width: 100%;
  height: 1px;
  padding: 0;
}

.grid {
  grid-column-gap: 40px;
  grid-row-gap: 0px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  width: 100%;
}

.grid.mod--timeline2 {
  overflow: hidden;
}

.grid.mod--timeline {
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.swiper-wrapper {
  flex-direction: row;
  width: 100%;
  height: 100%;
  display: flex;
}

.lines {
  background-image: url('/images/ico_star2.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
  position: absolute;
  top: auto;
  bottom: 0;
}

.lines.mod--timeline {
  inset: 0 auto auto 320px;
}

.swiper-slide {
  justify-content: center;
  align-items: stretch;
  height: auto;
  display: block;
  position: relative;
}

.swiper-slide.slide--reviews {
  flex-direction: column;
  flex: none;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding-top: 62px;
  display: flex;
}

.timeline__sticky {
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  min-height: 100vh;
  margin-left: -64px;
  margin-right: -64px;
  padding-left: 80px;
  padding-right: 80px;
  display: flex;
  position: sticky;
  top: 0;
  overflow: hidden;
}

.swiper {
  width: 100%;
  overflow: hidden;
}

.swiper.swiper--reviews-numb {
  width: 100%;
  overflow: visible;
}

.swiper.swiper--reviews {
  flex: 1;
  width: 100%;
  height: 100%;
  max-height: 320px;
  overflow: hidden;
}

.lines__line {
  background-color: #101010;
  width: 1px;
  height: 1px;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.lines__line.mod--timeline-1 {
  width: 340px;
}

.lines__line.mod--timeline-2 {
  width: 980px;
  left: 20px;
  right: auto;
}

.btn-tag__star {
  filter: contrast(128%) invert() grayscale();
  background-image: url('/images/ico_star2-menu.svg');
  background-position: 0 0;
  background-size: auto;
  flex: none;
  width: 10px;
  height: 10px;
  margin-right: 6px;
  transition: all .2s;
  display: block;
}

.timeline__ico {
  margin-bottom: 24px;
}

.content-2 {
  width: 100%;
  max-width: 1312px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.timeline__p {
  margin-bottom: 0;
  font-size: 22px;
}

.timeline__tags {
  align-items: center;
  margin-top: 40px;
  margin-bottom: 1px;
  display: flex;
}

.mask {
  overflow: visible;
}

.section-regular {
  padding: 80px 5%;
  position: relative;
  overflow: hidden;
}

.section-regular.bg-brown {
  background-color: var(--light-cream);
  border-top-color: #495a69;
}

.section-regular.bg-brown.p0-40 {
  padding-top: 0;
  padding-bottom: 40px;
}

.section-regular.background-cream {
  background-color: var(--light-cream);
  color: #404040;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
}

.key-trends.bg-brown {
  background-color: var(--light-cream);
  border-top-color: #495a69;
}

.section-regular.background-cream.postintro {
  z-index: 1;
  padding-top: 0;
  padding-bottom: 0;
  overflow: visible;
}

.section-regular.bg-brown-copy {
  background-color: var(--light-cream);
  border-top-color: #495a69;
}

.section-regular.dark {
  background-color: var(--dark-blue);
}

.section-regular.footer {
  background-color: var(--dark-blue);
  align-items: flex-end;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  overflow: visible;
}

.key-findings-section {
  padding-left: 0;
  padding-right: 0;
}

.slide {
  margin-right: 32px;
}

.heading-wrap {
  grid-column-gap: 16px;
  grid-row-gap: 24px;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  margin-bottom: 64px;
  display: flex;
}

.heading-wrap.m0-0 {
  margin-bottom: 0;
}

.testimonial-slider {
  padding-bottom: 112px;
  background-color: #0000;
  height: 100%;
  overflow: hidden;
}

.testimonial-slide {
  max-width: 384px;
}

.testimonial-slider.wide,
.testimonial-slide.wide {
  width: 800px;
  max-width: none;
  position: static;
}

.wrap-v-tiny {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-direction: column;
  display: flex;
}

.hide {
  display: none;
}

.testimonial-arrow {
  color: #a3a3a3;
  align-items: center;
  width: 48px;
  height: 48px;
  padding: 12px;
  transition: all .3s;
  display: flex;
  inset: auto auto 0% 0%;
}

.testimonial-arrow:hover {
  color: var(--turqoise);
}

.testimonial-arrow.right {
  left: 56px;
}

.flipcard-wrapper {
  position: relative;
}

.flipcard-front {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  background-color: var(--dark-blue);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  color: #fff;
  border-radius: 30px;
  flex-flow: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  height: 450px;
  padding-top: 60px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 7px 7px 40px 6px #00000017;
}

.flipcard-front.light {
  background-color: var(--white);
  justify-content: flex-end;
  align-items: stretch;
  height: 450px;
  padding-bottom: 60px;
}

.flipcard-title {
  letter-spacing: -.04em;
  margin-top: 0;
  margin-bottom: 6px;
  font-size: 28px;
  line-height: 32px;
}

.flipcard-title.dark {
  color: var(--dark-blue);
  white-space: pre-wrap;
}

.flipcard-more {
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.flipcard-fdi-target-back {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  background-color: #fff;
  border-radius: 30px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 450px;
  padding: 66px 107px 66px 44px;
  display: flex;
  position: absolute;
  inset: 0%;
  overflow: hidden;
  transform: rotateX(0)rotateY(180deg)rotateZ(0);
  box-shadow: 7px 7px 40px 6px #00000017;
}

.flipcard-less {
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.flipping-card-image {
  max-width: 100%;
  position: absolute;
  inset: auto auto -97px -73px;
}

.flipping-card-image.back {
  z-index: -3;
  opacity: .1;
  inset: -25% 0% auto 23%;
}

.fa-solid-span {
  color: var(--turqoise);
  font-family: "Fa Solid 900", sans-serif;
  font-size: 28px;
}

.fa-solid-span.dark {
  line-height: 1;
}

.fa-solid-span.white {
  color: var(--white);
}

.fa-solid-span.turqoise {
  color: var(--turqoise);
}

.fa-solid-span.turqoise._48px {
  font-size: 48px;
  line-height: 48px;
}

.fa-solid-span.brown {
  color: #8c8679;
}

.fa-solid-span.yellow {
  color: #f1a647;
}

.fa-solid-span.xsmall {
  font-size: 12px;
}

.fa-solid-span.small {
  font-size: 22px;
}

.container-large {
  z-index: 1;
  flex: 0 auto;
  width: 100%;
  max-width: 1208px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.container-large.postintro {
  top: -90px;
}

.testimonial-slider-container {
  z-index: 1;
  flex: 0 1 auto;
  width: 100%;
  /* max-width: 1208px; */
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: -90px;
}

.mockup-background {
  z-index: -1;
  border-radius: 50%;
  flex: none;
  width: 100%;
  position: absolute;
}

.mockup-background.native {
  border: 10px solid var(--lighter-blue);
  opacity: .3;
  width: 800px;
  height: 800px;
  inset: auto;
}

.mockup-background.middle-bg {
  border: 10px solid var(--lighter-blue);
  background-color: var(--lighter-blue);
  width: 450px;
  height: 450px;
  inset: auto;
}

.mockup-background.policy-areas {
  z-index: 0;
  border: 10px solid var(--white);
  opacity: .3;
  width: 1000px;
  height: 1000px;
  top: -450px;
}

.content-feature-item {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.content-mockup-wrap {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  min-width: 440px;
  padding-left: 28px;
  padding-right: 28px;
  display: flex;
  position: relative;
}

.content-main-wrap {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  grid-template: "_1-1 _1-2 _1-3" 240px
    "_2-1 _2-2 _2-3" 240px
    "_3-1 _3-2 _3-3" 240px
    / 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  display: grid;
}

.icon-wrap {
  background-image: linear-gradient(to bottom, var(--white), var(--white));
  border-radius: 50%;
  padding: 8px;
  box-shadow: 0 0 0 1px #0000000f, 0 1px 2px #0000000f;
}

.icon-wrap.dark {
  box-shadow: 0 2px 5px #0003, 0 1px 2px #0000000f;
}

.icon-wrap.no-shadow {
  box-shadow: none;
}

.icon-wrap.no-shadow.dark {
  background-image: none;
}

.content-features-column {
  grid-column-gap: 56px;
  grid-row-gap: 56px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 312px;
  display: flex;
}

.full-width-slider-section {
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.full-width-slider {
  background-color: #fff0;
  flex: 1;
  height: 800px;
}

.absolute-infographic-image {
  max-width: 50vw;
  max-height: 100%;
  position: absolute;
  inset: auto 0% 0% auto;
}

.absolute-infographic-image._2nd-slide {
  inset: auto auto 0% 0%;
}

.infographic-slide {
  padding: 100px 5%;
}

.infographic-content-grid {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: row;
  place-content: center start;
  align-items: center;
}

.infographic-content-grid._2nd-slide {
  grid-template-columns: 1fr 2fr;
  width: 100%;
}

.tab-link {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  opacity: .6;
  filter: grayscale();
  color: #525f75;
  background-color: #ddd0;
  align-items: flex-start;
  width: 100%;
  padding: 0;
}

.tab-link.w--current {
  opacity: 1;
  filter: none;
  color: #23272f;
  background-color: #ddd0;
}

.slider-container {
  z-index: 5;
  width: 100%;
  max-width: 1216px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: relative;
}

.mask-container {
  margin-left: auto;
  position: static;
}

.bento-card-image {
  z-index: 1;
  object-fit: contain;
  object-position: 50% 100%;
  padding-bottom: 30px;
  padding-left: 40px;
  padding-right: 40px;
  position: relative;
}

.bento-card {
  background-color: var(--white);
  border: 1px solid #edeff3;
  border-radius: 24px;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
  transition: all .3s cubic-bezier(.86, 0, .07, 1);
  display: flex;
  position: relative;
  overflow: hidden;
}

.bento-card:hover {
  transform: scale(1.02);
}

.bento-card.dark {
  background-color: var(--parliament-1);
}

.bento-card.bg {
  background-image: linear-gradient(180deg, var(--white), #fff0), url('/images/FDI-Strategy-Design-Guideline-Map_1.webp');
  background-position: 0 0, 150% 44%;
  background-repeat: repeat, no-repeat;
  background-size: auto, 80%;
  text-decoration: none;
}

.bento-card.bg._1 {
  background-image: linear-gradient(180deg, var(--white), #fff0), url('/images/Star-Graph.svg');
  background-position: 0 0, 95% 95%;
  background-size: auto, 20%;
}

.bento-card.bg._2 {
  background-image: linear-gradient(180deg, var(--white), #fff0), url('/images/new-startup_cool.avif');
  background-position: 0 0, 100% 100%;
  background-size: auto, 40%;
}

.bento-card.bg.startup {
  background-image: linear-gradient(180deg, var(--white), #fff0), url('/images/new-startup_cool.png');
  background-position: 0 0, 150% 0;
}

.bento-card.bg.key-findings {
  background-image: linear-gradient(180deg, var(--white), #fff0), url('/images/compass-bg_1.webp');
}

.bento-card.bg.primary-goals {
  background-image: linear-gradient(180deg, var(--white), #fff0), url('/images/fdi-strategy-cover_1.webp');
  background-position: 0 0, 120% 30%;
  background-size: auto, 50%;
}

.bento-card.bg.icon-show {
  background-image: linear-gradient(180deg, var(--white), #fff0), url('/images/0-new-rising-trend.avif');
  background-position: 0 0, 120% 20%;
  background-size: auto, 60%;
}

.bento-card.color-bg {
  background-color: var(--turqoise);
}

.bento-card.color-bg:hover {
  background-color: var(--light-purple);
}

.bento-card-text-wrapper {
  z-index: 1;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-direction: column;
  padding: 32px;
  display: flex;
  position: relative;
}

.bento-card-header {
  z-index: 1;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  padding: 32px;
  display: flex;
  position: relative;
}

.bento-card-header.no-gap {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.bento-card-header.gap20 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.bento-card-header.gap0 {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.wrap-v-small {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-direction: column;
  display: flex;
}

.bento-grid-wrapper {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-direction: column;
  display: flex;
}

.paragraph-x-large {
  letter-spacing: -.02em;
  margin-bottom: 0;
  font-family: Inter, sans-serif;
  font-size: 20px;
  line-height: 32px;
}

.paragraph-x-large.text-color-gray-600 {
  color: #525f75;
}

.bento-grid {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
}

.bento-grid._6-columns {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  grid-template-rows: 300px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-rows: 300px;
}

.bento-grid._3-columns {
  grid-template-columns: 1fr 1fr 1fr;
}

.bento-w-heading {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  flex-flow: column;
  display: flex;
}

.text-span {
  font-size: 16px;
}

.bento-card-icon {
  width: 100px;
}

.bullets-with-icons {
  grid-column-gap: 32px;
  grid-row-gap: 15px;
  flex-flow: column;
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
}

.tabs-content {
  flex-flow: row;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  height: 100%;
  display: flex;
}

.tab-image {
  width: 500px;
}

.h2-heading {
  letter-spacing: -2px;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 56px;
  font-weight: 700;
  line-height: 72px;
}

.h2-heading.middle {
  color: var(--dark-blue);
  text-align: center;
  line-height: 1.2;
}

.tab-rotation-embed {
  display: none;
}

.tab-link-text-wrapper {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  flex-direction: column;
  width: 100%;
  display: flex;
}

.tab-link-loader {
  background-color: #edeff3;
  border: 1px solid #d5d9e2;
  border-radius: 24px;
  align-items: center;
  width: 100%;
  height: 2px;
  display: flex;
}

.paragraph-small-2 {
  margin-bottom: 0;
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 20px;
}

.paragraph-small-2.text-color-gray-800 {
  color: #394151;
}

.tabs-component {
  flex-flow: row-reverse;
  align-items: center;
  display: flex;
}

.tabs-component.reverted {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  flex-flow: row;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  place-items: start center;
  display: flex;
}

.icon-regular {
  flex: none;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
}

.tab-link-horizontal {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-direction: row;
  align-items: flex-start;
  display: flex;
}

.tabs-menu {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  flex-direction: column;
  width: 100%;
  max-width: 528px;
  margin-left: 10%;
  display: flex;
}

.tabs-menu.reverted {
  max-width: 400px;
  margin-left: 0%;
}

.loader-bar {
  background-color: var(--peach-color);
  width: 0%;
  height: 2px;
}

.h6-heading {
  color: var(--light-purple);
  letter-spacing: -.5px;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}

.h6-heading.turqoise {
  color: var(--turqoise);
}

.h6-heading.turqoise.right-aligned {
  text-align: right;
  overflow-wrap: anywhere;
}

.h6-heading.yellow {
  color: var(--yellow);
}

.content-footer {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  align-items: center;
  margin-top: 24px;
  display: flex;
}

.paragraph-x-small {
  letter-spacing: .04px;
  margin-bottom: 0;
  font-size: 12px;
  line-height: 20px;
}

.paragraph-x-small.text-color-gray-600 {
  color: #525f75;
}

.paragraph-x-small.full-white {
  color: var(--white);
}

.icon-right {
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-left: 12px;
  display: flex;
}

.testimonial-mask {
  height: 700px;
  position: static;
  overflow: visible;
}

.key-info-div {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: var(--turqoise);
  border-radius: 16px;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 320px;
  padding: 24px;
  display: flex;
}

.slide-nav---hidden {
  display: none;
}

.paragraph-regular {
  color: var(--lighter-blue);
  letter-spacing: -.02em;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 32px;
}

.paragraph-regular.light {
  color: var(--white);
}

.key-outcome-slider {
  background-color: #ddd0;
  max-width: 858px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  position: static;
}

.infographics-slide {
  margin-right: 24px;
}

.paragraph-large {
  color: var(--dark-blue);
  letter-spacing: -.04em;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
}

.paragraph-large.light {
  color: var(--white);
}

.paragraph-large.limited {
  width: 50ch;
}

.paragraph-large.limited.bold {
  width: auto;
}

.paragraph-large.bold {
  font-weight: 500;
}

.testimonial-grid {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 2fr;
}

.testimonial-content-wrapper {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
}

.infographic-card {
  background-color: var(--light-purple);
  border-radius: 24px;
  flex-flow: row;
  width: 100%;
  height: 90%;
  padding: 24px;
  display: flex;
}

.wrap-v-large {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-direction: column;
  display: flex;
}

.section-key-outcomes {
  background-color: var(--dark-blue);
  padding-top: 96px;
  padding-bottom: 96px;
  position: relative;
  overflow: hidden;
}

.all-caps-info-text {
  color: var(--white);
  letter-spacing: .04em;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 800;
  line-height: 20px;
  text-decoration: none;
}

.key-fact {
  color: var(--white);
  letter-spacing: -.5px;
  text-transform: none;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 28px;
  font-weight: 800;
  line-height: 36px;
  text-decoration: none;
}

.number-fact {
  font-size: 56px;
}

.number-fact.bold {
  font-weight: 700;
}

.number-fact.mid {
  font-size: 36px;
  line-height: 42px;
}

.footer-link {
  color: #b6bcce;
  letter-spacing: -.01em;
  border: 1px solid #0000;
  border-radius: 10px;
  margin-left: 4px;
  margin-right: 4px;
  padding: 10px 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
  transition: all .2s;
}

.footer-link:hover {
  color: #fff;
  border: 1px solid #37456c;
}

.footer-link-wrapper {
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  display: flex;
}

.footer-logo {
  width: 240px;
  height: 240px;
  margin-bottom: 32px;
  display: flex;
}

.footer-logo.w--current {
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  margin-bottom: 0;
  position: relative;
}

.footer-logo.absolute {
  align-self: stretch;
  width: 240px;
  height: 240px;
  display: flex;
  position: absolute;
  inset: -75% auto auto 0%;
}

.paragraph-footer {
  color: #dadde7;
  letter-spacing: -.02em;
  max-width: 600px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
}

.footer-wrapper {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.footer-logo-wrapper {
  grid-column-gap: 100px;
  grid-row-gap: 100px;
  display: flex;
}

.section-footer {
  background-color: #334451;
  align-items: center;
  padding: 100px 5%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.nav-button-2 {
  color: #334451;
  cursor: pointer;
  margin: 0;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 28px;
  text-decoration: none;
  transition: all .2s cubic-bezier(.86, 0, .07, 1);
}

.nav-button-2:hover {
  color: #4d6577;
  transform: scale(1.05);
}

.nav-button-2.w--current {
  color: #4d6577;
}

.nav-button-2.light {
  color: #fff;
  text-transform: uppercase;
}

.container-large-4 {
  z-index: 5;
  width: 100%;
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.sticky-section {
  z-index: 101;
  position: sticky;
  top: 120px;
}

.sticky-section.sticky-160 {
  top: 160px;
}

.sticky-section.sticky-200 {
  top: 200px;
}

.sticky-section.top {
  top: 0;
}

.first-layer {
  background-color: #fff;
  border: 2px solid #fff;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  height: 80vh;
  margin-bottom: -40px;
  padding: 80px 80px 120px;
  position: relative;
}

.overlapping-section {
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  position: relative;
}

.second-layer {
  background-color: #fff;
  border: 2px solid #fff;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  align-items: center;
  height: 80vh;
  margin-bottom: -40px;
  padding: 80px 80px 120px;
  display: flex;
  position: relative;
  box-shadow: 0 -15px 40px #7089b01a;
}

.entrance-message {
  padding: 140px 5%;
}

.entrance-message.limited {
  max-height: 600px;
}

.entrance-message.limited.no-overflow {
  overflow: hidden;
}

.overlapping-bento {
  background-color: var(--white);
  border: 1px solid #edeff3;
  border-radius: 24px;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  position: relative;
  overflow: hidden;
}

.overlapping-bento.first-layer {
  height: auto;
  margin-bottom: -40px;
  padding-left: 60px;
  padding-right: 60px;
  overflow: visible;
}

.overlapping-bento.second-layer {
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: auto;
  margin-bottom: -40px;
  padding-left: 60px;
  padding-right: 60px;
}

.overlapping-grid-system {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-columns: 60% 40%;
  grid-template-rows: auto auto;
  align-items: end;
}

.number-display {
  background-image: linear-gradient(180deg, var(--turqoise), #66b6bf00 85%);
  text-align: right;
  letter-spacing: -.04em;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  margin-top: 0;
  margin-bottom: 0;
  padding-right: 10px;
  font-size: 160px;
  font-weight: 900;
  line-height: 1.2;
}

.number-display.turqoise.left {
  text-align: left;
}

.number-display.turqoise.left._120px {
  text-align: left;
  font-size: 140px;
}

.number-display.yellow {
  background-image: linear-gradient(180deg, var(--yellow), #66b6bf00 85%);
  align-self: flex-start;
  padding-left: 20px;
}

.number-display.cream {
  background-image: linear-gradient(180deg, var(--sand), #b5ad9c00 85%);
}

.number-display.white {
  background-image: linear-gradient(180deg, var(--white), #fff0 85%);
}

.number-display.dark {
  background-image: linear-gradient(180deg, var(--dark-blue), #33445100 85%);
}

.math-sign {
  font-size: 80px;
}

.fdi-target-card {
  position: relative;
}

.fdi-targetcard-bottom {
  background-color: var(--turqoise);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  color: #fff;
  border-radius: 0 0 30px 30px;
  flex-flow: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  height: 100px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 7px 7px 40px 6px #00000017;
}

.fdi-targets-grid {
  grid-column-gap: 9px;
  grid-row-gap: 16px;
  flex-flow: wrap;
  grid-template-rows: auto;
  grid-template-columns: 16% 16% 16% 16% 16% 16%;
  grid-auto-columns: 1fr;
  display: grid;
}

.mini-card-title {
  text-align: center;
  letter-spacing: -.04em;
  margin-top: 0;
  margin-bottom: 6px;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.fdi-targetcard-top {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  background-color: var(--dark-blue);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  color: #fff;
  border-radius: 30px 30px 0 0;
  flex-flow: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  height: 200px;
  padding: 20px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 7px 7px 40px 6px #00000017;
}

.fdi-target-icon {
  width: 60px;
}

.radial-grid-system {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template: "Area Area . Heading Heading Heading Heading . Area-6 Area-6" 1fr
    "Area Area . Heading Heading Heading Heading . Area-6 Area-6" 1fr
    ". Area-2 Area-2 . . . . Area-5 Area-5 ." 1fr
    ". Area-2 Area-2 Area-3 Area-3 Area-4 Area-4 Area-5 Area-5 ." 1fr
    ". . . Area-3 Area-3 Area-4 Area-4 . . ." 1fr
    / 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
  grid-auto-columns: 1fr;
  place-content: center;
  place-items: center;
  display: grid;
}

.radial-card {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  border: 6px solid var(--white);
  text-align: center;
  cursor: pointer;
  background-color: #76cbd4;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 180px;
  padding: 10px;
  text-decoration: none;
  transition: all .2s ease-in-out;
  display: flex;
  box-shadow: 0 0 40px #0003;
}

.radial-card:hover {
  transform: scale(1.02);
  box-shadow: 0 0 80px #0003;
}

.h5-heading {
  color: var(--dark-blue);
  letter-spacing: -.04em;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.2;
}

.h5-heading.light {
  color: var(--white);
}

.h5-heading.radial-card-heading {
  font-size: 20px;
}

.team-slider {
  background-color: #0000;
  height: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
  overflow: hidden;
}

.fdi-slider-section {
  background-color: var(--light-cream);
  flex-flow: column;
  justify-content: flex-start;
  align-items: stretch;
  padding-top: 96px;
  padding-bottom: 60px;
  display: flex;
  position: relative;
}

.team-slide {
  margin-right: 32px;
}

.generic-slider-trigger {
  /* background-image: linear-gradient(90deg, #fff0, #fff); */
  justify-content: flex-end;
  align-items: center;
  /* width: 20%; */
  transition: opacity .3s;
  display: flex;
  inset: 0% 0% 0% auto;
  opacity: 0;
}



.generic-slider-trigger.left {
  background-image: linear-gradient(270deg, #fff0, #fff);
  justify-content: flex-start;
  align-items: center;
  padding-left: 40px;
  display: flex;
  inset: 0% auto 0% 0%;
}

.generic-slider-trigger.left.dark-blue {
  background-image: linear-gradient(270deg, #2f434e00, var(--parliament-1));
}

.generic-slider-trigger.left.cream {
  background-image: linear-gradient(270deg, #f9f7f700, var(--light-cream));
}

.generic-slider-trigger.right {
  padding-right: 40px;
}

.generic-slider-trigger.right.dark-blue {
  background-image: linear-gradient(90deg, #2f434e00, var(--parliament-1));
}

.generic-slider-trigger.right.cream {
  background-image: linear-gradient(90deg, #f9f7f700, var(--light-cream));
}

.team-slider-mask {
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  overflow: visible;
}

.testimonial-slider .team-slider-mask {
  width: 1200px;
}

.slide-nav-hidden {
  display: none;
}

.quality-fdi-visual {
  width: 500px;
  max-width: 100%;
  position: absolute;
  top: -120px;
  left: auto;
  right: -100px;
}

.full-width-container {
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 40px;
  display: flex;
}

.mockup-holder {
  height: 300px;
  position: relative;
}

.mockup-image {
  width: 500px;
  position: absolute;
  inset: auto 0% 0%;
}

.button-left-icon {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  background-color: var(--turqoise);
  color: #fff;
  text-align: center;
  letter-spacing: -.04em;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 8px 20px;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  transition: all .3s;
  display: flex;
  position: relative;
}

.button-left-icon:hover {
  background-color: var(--light-purple);
}

.button-left-icon:active {
  background-color: var(--lighter-blue);
}

.button-left-icon:focus {
  outline-offset: 0px;
  outline: 2px solid #b6b9ce;
}

.button-icon-left {
  justify-content: center;
  align-items: center;
  font-family: "Fa Solid 900", sans-serif;
  display: flex;
}

.policy-areas-bg {
  background-color: var(--light-cream);
  opacity: .1;
  border-radius: 50%;
  width: 600px;
  height: 600px;
  position: absolute;
  inset: -225px auto auto;
}

.embed {
  display: none;
}

.content-carousel-wrap {
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.icon-gigantic {
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 160px;
  display: flex;
}

.content-carousel-overlay {
  z-index: 2;
  background-image: linear-gradient(90deg, var(--white), #fff0 20%, #fff0 80%, var(--white));
  pointer-events: none;
  position: absolute;
  inset: 0%;
}

.content-carousel-logo {
  background-color: #0000;
  border: 1px #f2eeee;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  padding: 16px 24px;
  transition: background-color .3s;
  display: flex;
}

.content-carousel-logo:hover {
  background-color: #fff;
  border-color: #fff;
}

.content-carousel-group {
  flex-flow: row;
  flex: none;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.content-carousel {
  flex: none;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.icon {
  color: #a3a3a3;
  align-items: center;
  width: 48px;
  height: 48px;
  padding: 12px;
  transition: all .3s;
  display: flex;
  inset: auto auto 0% 0%;
}

.icon:hover {
  color: var(--turqoise);
}

.icon.sharp {
  width: auto;
  height: auto;
  padding: 0;
}

.content-tab-pane {
  overflow: hidden;
}

.loader-bar-2 {
  background-color: var(--turqoise);
  height: 8px;
}

.content-tab-link-2 {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  background-color: var(--light-cream);
  border-style: none;
  border-width: 1px;
  flex-flow: column;
  align-items: flex-start;
  padding: 32px;
  display: flex;
  overflow: hidden;
}

.content-tab-link-2.w--current {
  background-color: var(--white);
}

.tab-link-loader-2 {
  position: absolute;
  inset: 0% 0% auto;
}

.container-large-7 {
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.content-tabs-menu {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.hero-grid {
  grid-template-rows: auto;
  grid-template-columns: 2fr 500px;
}

.index-trigger {
  background-color: #fff;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: none;
}

.index-wrapper {
  width: 100%;
  overflow: hidden;
}

.index-item-wrapper {
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  display: flex;
}

.burger-line {
  background-color: #000;
  border-radius: 2px;
  width: 22px;
  height: 2px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.contact-nav {
  justify-content: flex-end;
  width: auto;
  height: 40px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.index-chapter {
  flex: 1;
  align-items: center;
  height: auto;
  display: flex;
  position: relative;
}

.icon-link {
  opacity: .3;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  text-decoration: none;
  transition: opacity .3s;
  display: flex;
}

.icon-link:hover {
  opacity: 1;
}

.index-item {
  opacity: .3;
  color: #000;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  flex: 1;
  padding-top: 9px;
  padding-bottom: 9px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  text-decoration: none;
  transition: opacity .3s, color .3s, border-color .3s, background-color .3s;
  position: relative;
}

.index-item:hover {
  opacity: .9;
  color: #000;
  background-color: #fff;
}

.index-item.w--current {
  opacity: 1;
  color: #000;
  background-color: #0000;
  font-weight: 600;
}

.point {
  background-color: var(--peach-color);
  width: 20px;
  height: 4px;
  margin-left: 0;
  margin-right: -20px;
}

.index-trigger-wrapper {
  align-items: flex-start;
  height: 100%;
  display: flex;
}

.index {
  z-index: 0;
  border-top: 1px solid #fff6;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.hamburger-wrapper {
  opacity: 1;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
}

.contact-trigger {
  z-index: 10;
  background-image: linear-gradient(90deg, #f9f7f700, var(--light-cream));
  color: var(--light-purple);
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  padding: 4px 0;
  transition: all .3s;
  display: flex;
  position: absolute;
  inset: auto 0% 0% auto;
}

.contact-trigger:hover {
  color: var(--turqoise);
  transform: scale(1.1);
}

.index-progress {
  background-color: #ffffff80;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 4px;
  display: flex;
}

.contact-block {
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding-right: 60px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.index-progress-bar {
  background-color: var(--peach-color);
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  transition: width .3s cubic-bezier(.165, .84, .44, 1);
  display: flex;
}

.item-line {
  background-color: #f2f2f2;
  width: 1px;
  height: 10px;
  display: none;
  position: absolute;
  inset: auto 0% 0% auto;
}

.item-line.is-start {
  inset: auto auto 0% 0%;
}

.action-plans-section {
  background-color: var(--light-cream);
  padding-left: 5%;
  padding-right: 5%;
}

.policy-area-section {
  flex-flow: column;
  padding-top: 0;
  padding-bottom: 100px;
  display: flex;
}

.policy-column-holder {
  grid-template-columns: 30% 1fr;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
}

.policy-group-card {
  background-color: var(--medium-cream);
  background-image: none;
  border: 1px #edeff3;
  border-radius: 24px;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.policy-column-1 {
  width: 25%;
}

.policy-column-2 {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-flow: column;
  width: 75%;
  display: flex;
}

.policy-area-card {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  background-color: var(--dark-blue);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  color: #fff;
  border-radius: 30px;
  flex-flow: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  height: 600px;
  padding-top: 60px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 7px 7px 40px 6px #00000017;
}

.policy-area-card.light {
  background-color: var(--white);
  justify-content: flex-end;
  align-items: stretch;
  height: 500px;
  padding-bottom: 60px;
  padding-left: 30px;
  padding-right: 30px;
  position: sticky;
  top: 160px;
}

.faq-container {
  z-index: 5;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.faq-content-wrap {
  background-color: #0000;
  display: block;
  position: static;
  overflow: hidden;
}

.faq-icon-circle {
  background-color: var(--turqoise);
  border-radius: 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
}

.faq-section {
  color: #4d4d4d;
  text-align: center;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 0;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.faq-item {
  border-style: none;
  border-width: 1px;
  border-color: var(--lighter-blue) #e5e5e5 #e5e5e5;
  background-color: var(--light-cream);
  border-radius: 16px;
  flex-flow: column;
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
}

.faq-title-wrapper {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  border-left: 6px solid var(--turqoise);
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
  display: grid;
}

.faq-snippet {
  display: none;
}

.faq-content {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  flex-flow: column;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
  line-height: 32px;
  display: flex;
}

.faq-item-toggle {
  color: #8997a9;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  width: 100%;
}

.faq-item-toggle:hover {
  color: #338cff;
}

.faq-wrapper {
  flex-flow: column;
  line-height: 32px;
  display: flex;
}

.action-plan-card-heading {
  color: var(--dark-blue);
  letter-spacing: -.04em;
  max-width: 40ch;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 22px;
  line-height: 1.2;
}

.related-partners-grid {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  background-color: var(--white);
  border-radius: 8px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 3fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  padding: 16px;
  display: grid;
}

.micro-heading {
  color: var(--turqoise);
  letter-spacing: -.04em;
  max-width: 40ch;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
}

.action-number {
  display: flex;
}

.policy-area-visual {
  width: 500px;
  max-width: 100%;
  position: absolute;
  top: -35px;
  left: auto;
  right: -68px;
}

.footer-link-wrap {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  align-items: center;
  display: flex;
}

.footer-bottom {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.footer-link-2 {
  color: var(--white);
  letter-spacing: -.02em;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 14px;
  line-height: 1.8;
  text-decoration: none;
}

.footer-link-2:hover {
  color: var(--turqoise);
}

.footer-top-row {
  grid-column-gap: 40px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.container-regular-3 {
  z-index: 5;
  width: 100%;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.footer-center {
  grid-column-gap: 48px;
  align-items: center;
  display: flex;
}

.footer-divider-large {
  background-color: #e4e4e7;
  width: 100%;
  height: 1px;
  margin-top: 64px;
  margin-bottom: 56px;
}

.footer-legal-wrap {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  align-items: center;
  display: flex;
}

.footer-divider-small {
  background-color: #e4e4e7;
  width: 1px;
  height: 16px;
}

.footer-visual {
  max-width: 300px;
}

.list {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-flow: column;
  padding-left: 20px;
  list-style-type: square;
  display: flex;
}

.counter-text {
  color: var(--yellow);
  letter-spacing: -.48px;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.content-statistics {
  grid-column-gap: 20px;
  grid-row-gap: 60px;
  flex-flow: wrap;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  display: grid;
}

.counter-wrap {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  border-left: 4px solid var(--yellow);
  justify-content: flex-start;
  align-items: flex-end;
  padding-left: 10px;
  line-height: 1.2;
  display: flex;
}

.counter-wrap.grayscale {
  opacity: .42;
  filter: grayscale();
}

.content-statistics-wrapper {
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  flex-flow: column;
  display: flex;
}

.stat-heading {
  color: var(--dark-blue);
  letter-spacing: -.48px;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
}

.content-card {
  grid-column-gap: 64px;
  grid-row-gap: 64px;
  border: 1px #f3f4f6;
  border-radius: 16px;
  flex-flow: column;
  display: flex;
}

.stats-grid {
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.stats-grid.grid {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  border-top: 1px solid var(--light-purple);
  grid-template-rows: auto;
  grid-template-columns: 1fr 2fr;
  grid-auto-columns: 1fr;
  padding-top: 20px;
  padding-bottom: 20px;
  display: grid;
}

.stats-grid.grid.no-border {
  border-top-style: none;
  grid-template-columns: 200px 2fr;
}

.stats-grid.gap20 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.content-pane-wrapper {
  background-color: var(--white);
  border-radius: 0 0 40px 40px;
  padding: 40px;
}

.navigation-logo {
  height: 48px;
}

.tab-bento-card {
  background-color: var(--white);
  border: 1px solid #edeff3;
  border-radius: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 600px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.stat-graph {
  flex: none;
  align-self: flex-end;
  width: 200px;
}

.stat-graph._400px {
  align-self: center;
  width: 400px;
}

.keyfindings-text-holder {
  padding: 20px;
}

.keyfindings-text-holder .h4-heading {
  margin-bottom: 10px;
}

.keyfindings-card {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  background-color: var(--white);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  color: #fff;
  border-radius: 30px;
  flex-flow: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  height: 600px;
  padding-top: 60px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 7px 7px 40px 6px #00000017;
}

.keyfinding-visual-holder {
  width: 200px;
  height: 200px;
  overflow: visible;
}

.header-row {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.card-visual {
  height: 400px;
  position: absolute;
  inset: -100px auto auto -25px;
}

.section-intro {
  background-color: var(--turqoise);
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 60px 5%;
  display: flex;
  position: relative;
}

.section-intro.yellow {
  background-color: var(--yellow);
}

.section-intro.medium-cream {
  background-color: var(--medium-cream);
}

.section-intro.medium-cream.background {
  background-image: linear-gradient(#e4dbccb3, #e4dbccb3), url('/images/compass-bg_1.webp');
  background-position: 0 0, 150% -300px;
  background-repeat: repeat, no-repeat;
  background-size: auto, 70%;
}

.section-intro-header {
  z-index: 1;
  flex-flow: column;
  justify-content: center;
  align-self: flex-start;
  align-items: flex-start;
  padding-bottom: 40px;
  display: flex;
  position: relative;
}

.bg-visual-absolute-image {
  z-index: 0;
  background-image: linear-gradient(90deg, #000, #fff);
  position: absolute;
  inset: 0% -25% 0% auto;
}

.master-section {
  overflow: hidden;
}

.fontawesome {
  font-family: "Fa Solid 900", sans-serif;
}

.slider-chevron {
  color: var(--lighter-blue);
  font-family: "Fa Solid 900", sans-serif;
  font-size: 40px;
  line-height: 60px;
}

.generic-slider-trigger.dark-blue .slider-chevron {
  color: var(--turqoise);
}

.qfdi-card-heading {
  color: var(--dark-blue);
  letter-spacing: -.04em;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 38px;
  line-height: 72px;
  text-decoration: none;
  max-width: 550px;
}

.navbar-wrapper {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: #fff3;
  flex-flow: column;
  display: flex;
  position: fixed;
}

.action-side {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-flow: column;
  display: flex;
}

.fa-brands-span {
  color: var(--dark-blue);
  -webkit-text-stroke-color: var(--light-purple);
  font-family: "Fa Brands 400", sans-serif;
  font-size: 24px;
  text-decoration: none;
}

.fa-brands-span.light {
  color: var(--white);
}

.hamburger {
  color: #334451;
  cursor: pointer;
  margin: 0;
  font-family: "Fa Solid 900", sans-serif;
  font-size: 28px;
  font-weight: 800;
  line-height: 28px;
  text-decoration: none;
  transition: all .2s cubic-bezier(.86, 0, .07, 1);
}

.hamburger:hover {
  color: #4d6577;
  transform: scale(1.05);
}

.hamburger.w--current {
  color: var(--lighter-blue);
}

.dropdown-toggle {
  padding-right: 20px;
}

.dropdown-list {
  inset: 0%;
}

.dropdown-list.w--open {
  position: fixed;
  inset: 120px 0% 0%;
}

.dropdown-button {
  color: #334451;
  cursor: pointer;
  margin: 0;
  font-size: 16px;
  font-weight: 800;
  line-height: 28px;
  text-decoration: none;
  transition: all .2s cubic-bezier(.86, 0, .07, 1);
}

.dropdown-button:hover {
  color: #4d6577;
  transform: scale(1.05);
}

.dropdown-button.w--current {
  color: var(--lighter-blue);
}

.text-block-4 {
  font-size: 16px;
}

.a-filter-radio {
  grid-column-gap: 6px;
  background-color: #f4f4f6;
  border-radius: 8px;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  width: 220px;
  padding: 8px;
  display: flex;
}

.a-filter-radio.jetboost-list-search-reset-xr5q {
  background-color: var(--white);
  width: auto;
  padding-right: 16px;
}

.a-filter-dropdown-large {
  width: 100%;
  max-width: 300px;
  margin-left: 0;
  margin-right: 0;
}

.a-filter-checkbox-large {
  z-index: 10;
  border-style: none;
  width: 100%;
  height: 100%;
  margin-top: 0;
  margin-left: 0;
  margin-right: 16px;
  position: absolute;
}

.a-filter-checkbox-large.w--redirected-checked {
  background-color: #0000;
  background-image: url('/images/check.svg');
  background-position: 90%;
  background-size: 16px 16px;
  border-style: none;
}

.a-filter-checkbox-large.w--redirected-focus {
  box-shadow: none;
  border-style: none;
}

.a-subheading-regular {
  flex: none;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.8;
}

.a-subheading-regular:hover {
  color: var(--turqoise);
}

.a-filter-checkbox {
  cursor: pointer;
  background-color: #66b6bf33;
  border-style: none;
  border-radius: 8px;
  flex: none;
  width: 24px;
  height: 24px;
  margin-top: 0;
  margin-left: 0;
  margin-right: 16px;
}

.a-filter-checkbox.w--redirected-checked {
  background-color: var(--turqoise);
  background-image: url('/images/check-primary.svg');
  background-size: 16px 16px;
  border-style: none;
}

.a-filter-checkbox.w--redirected-focus {
  box-shadow: none;
  border-style: none;
}

.a-filter-checkbox.white {
  background-color: var(--white);
}

.a-filter-dropdown-wrapper-small {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  display: flex;
}

.a-filter-form-top {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  color: #131316;
  flex-flow: column;
  grid-template-rows: auto;
  grid-template-columns: 1fr 2fr;
  grid-auto-columns: 1fr;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  display: flex;
}

.a-filter-dropdown-toggle {
  grid-column-gap: 8px;
  background-color: var(--white);
  border: 1px solid #dedfe3;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 12px 16px;
  display: flex;
}

.a-filter-dropdown-toggle:hover,
.a-filter-dropdown-toggle.w--open {
  color: var(--turqoise);
}

.a-filter-dropdown-list {
  z-index: 100;
  margin-top: 8px;
}

.a-filter-dropdown-list.w--open {
  background-color: #fff;
  border-radius: 8px;
  margin-top: 8px;
  padding: 8px;
  box-shadow: 1px 1px 20px #0000000d;
  max-height: 300px;
  overflow: auto;
}

.a-filter-search {
  grid-column-gap: 8px;
  background-color: #fff;
  border-radius: 8px;
  flex: 1;
  align-items: center;
  padding: 8px;
  display: flex;
}

.a-filter-dropdown-small {
  flex: none;
}

.a-filter-dropdown-checkbox-field {
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 0;
  display: flex;
  position: relative;
}

.a-filter-dropdown-checkbox-field:hover {
  background-color: #66b6bf33;
  border-radius: 8px;
}

.a-filter-label {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
}

.a-filter-label:hover {
  color: var(--turqoise);
}

.a-checkbox-text {
  z-index: 9;
  letter-spacing: -.02em;
  margin-bottom: 0;
  margin-left: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.8;
  position: relative;
}

.a-filter-dropdown-heading {
  letter-spacing: -.02em;
  margin-bottom: 0;
  padding: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.8;
}

.a-filter-dropdown-wrapper {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex: 0 auto;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.a-filter-filter-form-block-top {
  width: 100%;
  max-width: 500px;
  margin-bottom: 0;
}

.a-filter-filter-form-block-top.full {
  max-width: none;
}

.a-filter-search-text-field {
  background-color: #0000;
  border: 1px #000;
  height: 32px;
  margin-bottom: 0;
  padding: 0;
}

.a-filter-search-text-field.jetboost-list-search-input-xr5q {
  font-size: 20px;
}

.a-filter-checkbox-field {
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
  padding-left: 8px;
  display: flex;
}

.a-filter-checkbox-field:hover {
  color: #5167f6;
}

.a-filter-layout-icon-wrapper {
  border-radius: 8px;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  display: flex;
}

.a-filter-layout-icon-wrapper.a-active {
  background-color: #fff;
}

.a-icon-small {
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  display: flex;
}

.a-filter-dropdown-toggle.w--open .a-icon-small {
  transform: rotate(180deg);
}

.a-filter-search-icon-wrapper {
  background-color: #fff;
  border-radius: 8px;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  display: flex;
}

.filtering-bar {
  z-index: 15;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  background-color: var(--white);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  color: #fff;
  border-radius: 24px;
  flex-flow: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  padding: 20px;
  display: flex;
  position: sticky;
  top: 100px;
  overflow: visible;
  box-shadow: 7px 7px 40px 6px #00000017;
}

.filtering-bar.hide {
  display: none;
}

.clear-filter {
  color: var(--lighter-blue);
  border-radius: 8px;
  flex: none;
  justify-content: center;
  align-items: center;
  height: 32px;
  text-decoration: none;
  display: flex;
  cursor: pointer;
}

.filter-clear-icon {
  color: var(--peach-color);
  font-family: "Fa Solid 900", sans-serif;
}

.marquee-item {
  cursor: pointer;
  border-left: 1px solid #dfdfdf3b;
  flex-direction: column;
  flex: none;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.mini-card-visual {
  width: 60%;
  max-width: 100%;
  position: absolute;
  top: 0%;
  left: auto;
  right: -10%;
}

.mini-filtering-card {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  background-color: var(--light-cream);
  color: #fff;
  cursor: pointer;
  border-radius: 30px;
  flex-flow: column;
  flex: 1;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: flex-start;
  align-items: flex-start;
  height: 200px;
  padding: 20px;
  text-decoration: none;
  transition: all .2s ease-in-out;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 1px 20px 1px #00000017;
}

.mini-filtering-card:hover {
  filter: none;
  transform: scale(1.05);
  box-shadow: 7px 7px 40px 6px #00000017;
}

.mini-filtering-card:lang(en) {
  filter: none;
}

.filtering-list {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex-flow: row;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: row dense;
  align-items: stretch;
  display: grid;
}

.filtering-container {
  z-index: 1;
  flex: 0 auto;
  width: 100%;
  max-width: 1208px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.filtering-title {
  color: var(--dark-blue);
  letter-spacing: -.04em;
  margin-top: 0;
  margin-bottom: 6px;
  font-size: 18px;
  line-height: 1.2;
  max-width: 150px;
}

.filtering-number-display {
  background-image: linear-gradient(180deg, var(--turqoise), #66b6bf00 85%);
  text-align: left;
  letter-spacing: -.04em;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  margin-top: 0;
  margin-bottom: 0;
  padding-right: 10px;
  font-size: 60px;
  font-weight: 900;
  line-height: 1.2;
  text-decoration: none;
}

.filtering-heading-set {
  grid-row-gap: 2px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 648px;
  text-decoration: none;
  display: flex;
}

.collection-item {
  display: flex;
}

.area-holder {
  z-index: 11;
  flex: 0 auto;
  width: 100%;
  max-width: 1208px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.policy-area-collection {
  z-index: 11;
  position: relative;
}

.insights-entrance {
  padding: 140px 5% 60px;
}

.dropdown-link {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  color: var(--lighter-blue);
  white-space: nowrap;
  border: 1px solid #fff0;
  border-radius: 8px;
  justify-content: flex-start;
  align-items: center;
  height: 48px;
  padding: 8px 12px;
  font-weight: 500;
  text-decoration: none;
  transition: border .3s, color .3s, background-color .3s;
  display: flex;
}

.dropdown-link:hover {
  color: var(--dark-blue);
  background-color: #e4dbcc4d;
  border: 1px solid #f0edea;
}

.dropdown-link.desktop-none {
  display: none;
}

.dropdown-links-list {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-flow: column;
  display: flex;
}

.nav-menu {
  width: 100%;
}

.navbar-v2 {
  z-index: 1;
  flex-flow: column;
  width: 100vw;
  padding: 16px 5%;
  display: flex;
  position: relative;
}

.dropdown-side-menu {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  flex-flow: column;
  justify-content: space-between;
  display: flex;
}

.nav-container {
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.nav-menu-grid {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 2fr;
  justify-content: space-between;
  display: grid;
}

.nav-logo-link {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  justify-content: center;
  align-items: center;
  transition: all .2s ease-in-out;
  display: flex;
}

.nav-logo-link:hover {
  transform: scale(1.05);
}

.nav-logo-link.hide-tablet {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.nav-logo-link.hide-desktop {
  display: none;
}

.nav-dropdown {
  align-self: center;
  margin-left: 0;
  margin-right: 0;
  position: static;
}

.nav-content-left {
  grid-column-gap: 64px;
  grid-row-gap: 64px;
  flex: 1;
  display: flex;
}

.nav-divider {
  background-color: var(--lighter-blue);
  width: 1px;
  height: 24px;
}

.nav-divider.full-height {
  height: 100%;
}

.nav-dropdown-content {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  width: 100%;
  display: flex;
}

.nav-dropdown-list-medium {
  background-color: #fff;
  border-radius: 8px;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px;
  display: flex;
}

.nav-dropdown-list-medium.w--open {
  background-color: #fff;
  border-radius: 16px;
  padding: 12px;
  inset: 64px auto auto 0%;
}

.nav-button-group {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  flex: none;
  display: flex;
}

.nav-content-right {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.dropdown-links-group {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  flex-flow: column;
  display: flex;
}

.nav-dropdown-list {
  inset: 0%;
}

.nav-dropdown-list.w--open {
  background-color: #0000;
  display: flex;
  position: absolute;
  inset: 57px auto auto;
}

.dropdown-icon-wrapper {
  background-color: #fff;
  border-radius: 100%;
  width: 28px;
  height: 28px;
  padding: 6px;
  box-shadow: 0 3px 2px #201f1f00, 0 2px 1px #201f1f03, 0 1px 1px #201f1f0a, 0 1px 1px #201f1f12;
}

.dropdown-icon-wrapper.turkish {
  background-image: url('/images/Turkey-flag.png');
  background-position: 0 0;
  background-size: cover;
}

.dropdown-icon-wrapper.english {
  background-image: url('/images/UK-flag.png');
  background-position: 0 0;
  background-size: cover;
}

.dropdown-icon-wrapper.french {
  background-image: url('/images/France-flag.png');
  background-position: 0 0;
  background-size: cover;
}

.button-text {
  font-weight: 700;
}

.navbar-mobile-button-set,
.download-button-mobile-nav {
  display: none;
}

.search-nav {
  z-index: 0;
  flex-flow: column;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  position: relative;
}

.sticky-search-bar {
  z-index: 10;
  flex-flow: column;
  display: flex;
  position: sticky;
  top: 0;
}

.collection-list {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: column;
  display: flex;
}

.generic-policy-group-card {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  background-color: var(--white);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  color: #fff;
  border-radius: 30px;
  flex-flow: column;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  padding: 40px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 7px 7px 40px 6px #00000017;
}

.search-bar-container {
  z-index: 1;
  flex: 0 auto;
  width: 100%;
  max-width: 1208px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  position: relative;
}

.full-wrap-search-holder {
  z-index: 10;
  background-image: linear-gradient(180deg, var(--light-cream), #f9f7f700);
  flex-flow: column;
  display: flex;
  position: relative;
}

.full-wrap-category-holder {
  z-index: 11;
  flex-flow: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: -60px;
  padding: 20px 5% 40px;
  position: relative;
  overflow: auto;
}

.success-message {
  background-color: #f5f5f5;
  border-radius: 10px;
}

.input-icon {
  flex: none;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  display: flex;
  position: absolute;
  inset: 10px auto 10px 10px;
}

.icon-x-small {
  flex: none;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  display: flex;
}

.icon-large {
  flex: none;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  transition: color .2s;
  display: flex;
}

.paragraph-regular-2 {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.7;
}

.footer-grid {
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 2fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.footer-scroll-link {
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  text-decoration: none;
}

.footer-links-grid-small {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: flex-start;
  display: grid;
}

.container-large-8 {
  z-index: 1;
  width: 100%;
  max-width: 1208px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.submit-button {
  text-align: left;
  background-color: #ff4b0f;
  background-image: linear-gradient(#ffffff3d, #0000 50%);
  border-radius: 10px;
  padding: 12px 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  box-shadow: inset 1px 1px .25px #ffffff1f, inset -1px 1px .25px #ffffff1f, 0 0 0 1px #ff4b0f, 0 1px 2px #ff4b0fa3;
}

.submit-button.small {
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
}

.footer-legal-wrap-2 {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  color: #666;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  display: grid;
}

.footer-link-badge {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  align-items: center;
  display: flex;
}

.error-message {
  color: #8f2400;
  background-color: #ffd1c2;
  border-radius: 10px;
}

.footer-feature {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  color: #fff;
  align-items: center;
  display: flex;
}

.footer-features {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  flex-wrap: wrap;
  display: flex;
}

.footer-details {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  color: #8f8f8f;
  flex-direction: column;
  justify-content: space-between;
  max-width: 272px;
  display: flex;
}

.footer-form-block {
  width: 100%;
  margin-bottom: 0;
}

.label-regular {
  color: #000;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.7;
}

.label-regular.text-white {
  color: #fff;
}

.wrap-v-xsmall {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  border-top: 1px solid var(--super-light);
  flex-direction: column;
  padding-top: 20px;
  display: flex;
}

.legal-links-small {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  align-items: center;
  display: flex;
}

.section-legal-dark {
  background-color: var(--light-purple);
  padding: 10px 3%;
}

.text-field-dark {
  background-color: #0000;
  border: 1px solid #292929;
  border-radius: 10px;
  min-height: 40px;
  margin-bottom: 0;
  padding: 10px 10px 10px 38px;
}

.text-field-dark:focus {
  border-color: #ff4b0f;
}

.footer-social-link {
  color: var(--super-light);
  cursor: pointer;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  font-size: 18px;
  text-decoration: none;
  transition: all .2s cubic-bezier(.165, .84, .44, 1);
  display: flex;
}

.footer-social-link:hover {
  color: #fff;
  transform: scale(1.08);
}

.footer-category {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: column;
  flex: 1;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}

.footer-form {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-direction: column;
  display: flex;
}

.footer-logo-2 {
  height: 32px;
}

.footer-legal-link-dark {
  color: #c9c9c9;
  font-size: 12px;
  line-height: 20px;
  text-decoration: none;
  transition: color .2s;
}

.footer-legal-link-dark:hover {
  color: #fff;
}

.form-input-icon {
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.footer-links-column {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.max-width-regular {
  width: 100%;
  max-width: 480px;
}

.text-color-white {
  color: #fff;
}

.footer-social-links {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  align-self: flex-start;
  align-items: center;
  display: flex;
}

.footer-sub-link {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  color: var(--super-light);
  cursor: pointer;
  align-items: center;
  font-size: 12px;
  line-height: 1.4;
  text-decoration: none;
  transition: all .2s cubic-bezier(.165, .84, .44, 1);
  display: flex;
}

.footer-sub-link:hover {
  color: var(--turqoise);
}

.footer-sub-link.right {
  justify-content: flex-end;
  align-items: center;
}

.footer-sub-link.main {
  color: var(--light-cream);
}

.footer-sub-link.main:hover {
  color: var(--turqoise);
}

.section-regular-2 {
  padding: 92px 5%;
  position: relative;
}

.section-regular-2.background-dark {
  background-color: var(--dark-blue);
}

.footer-heading {
  cursor: pointer;
  flex: none;
  margin: auto 0;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  font-weight: 800;
  line-height: 28px;
  text-decoration: none;
  transition: all .2s cubic-bezier(.86, 0, .07, 1);
}

.footer-heading:hover {
  transform: none;
}

.footer-heading.w--current {
  color: var(--lighter-blue);
}

.footer-heading.right-aligned {
  text-align: right;
}

.footer-absolute-image {
  flex: 1;
  align-self: center;
  min-width: 240px;
  min-height: 240px;
}

.report-visual-footer {
  position: relative;
  inset: auto 0% 0%;
}

.footer-social {
  font-family: "Fa Brands 400", sans-serif;
  text-decoration: none;
}

.footer-social.light {
  color: var(--white);
}

.footer-bottom-logo {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: column;
  justify-content: flex-end;
  align-self: center;
  align-items: center;
  display: flex;
}

.logo-visual._50 {
  opacity: .5;
  max-width: 200px;
}

.footer-image-link {
  opacity: .5;
  cursor: pointer;
  height: 40px;
  transition: opacity .2s cubic-bezier(.165, .84, .44, 1);
  display: flex;
}

.footer-image-link:hover {
  opacity: 1;
  transform: scale(1.05);
}

.small-footer-visual._50 {
  opacity: .5;
  max-width: 200px;
}

.image-2 {
  display: inline-block;
}

.footer-column.column-1 {
  margin-top: -120px;
  position: relative;
}

.text-block-5 {
  line-height: 1.7;
}

.text-span-2 {
  font-family: "Fa Solid 900", sans-serif;
}

.div-block-2 {
  width: 300px;
  height: 300px;
  transform: translate(0, 90px);
}

.full-width-tab-holder {
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.wide-slider {
  position: relative;
}

.text-icon {
  flex: none;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-family: "Fa Solid 900", sans-serif;
  transition: color .2s;
  display: flex;
}

.text-icon.reddish {
  color: var(--peach-color);
}

.text-icon.green {
  color: var(--green);
}

.policy-area-card-title {
  color: var(--light-purple);
  letter-spacing: -.04em;
  margin-top: 0;
  margin-bottom: 6px;
  font-size: 28px;
  line-height: 32px;
}

.policy-area-card-title.dark {
  color: var(--dark-blue);
  white-space: pre-wrap;
}

.form {
  border-radius: 10px;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.close-icon {
  transition: opacity .3s;
  top: 4%;
  right: 4%;
}

.close-icon:hover {
  opacity: .4;
}

.submit-button-2 {
  background-color: #5eb8b8;
  padding: 24px 36px;
  font-size: 18px;
  transition: background-color .3s;
}

.submit-button-2:hover {
  background-color: #46a0a0;
}

.check-icon {
  background-color: #5eb8b8;
  border-radius: 8px;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-right: 14px;
  display: flex;
}

.modal-container {
  background-color: #fff;
  background-image: linear-gradient(0deg, var(--white) 56%, #fff0), url('/images/FDI-Strategy-Design-Guideline-Visualized-EN-Reduced_1.avif');
  background-position: 0 0, 50% -100%;
  background-repeat: repeat, no-repeat;
  background-size: auto, contain;
  border-radius: 30px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  max-width: 840px;
  margin-left: auto;
  margin-right: auto;
  padding: 100px 5%;
  display: flex;
  position: relative;
}

.modal-paragraph {
  color: #4f4f4f;
  max-width: 360px;
  font-size: 18px;
  line-height: 1.5;
}

.subscribe-text {
  color: #4f4f4f;
  text-align: left;
  font-size: 16px;
  line-height: 24px;
}

.subscribe-wrap {
  align-items: center;
  margin-top: 32px;
  display: flex;
}

.success-message-2 {
  background-color: #5eb8b8;
  border-radius: 12px;
  padding-left: 32px;
  padding-right: 32px;
}

.modal-heading {
  color: var(--dark-blue);
  letter-spacing: -.03em;
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 36px;
  line-height: 56px;
}

.form-block-modal {
  margin-top: 64px;
  margin-bottom: 32px;
}

.email-field {
  background-color: #f5f4f4;
  border: 1px #000;
  height: 100%;
  margin-bottom: 0;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 28px;
  font-size: 18px;
}

.error-message-2 {
  border-radius: 12px;
  padding-top: 16px;
  padding-bottom: 16px;
  position: relative;
}

.modal-section {
  z-index: 1000;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: #33445199;
  justify-content: center;
  align-items: center;
  padding: 100px 3%;
  display: flex;
  position: fixed;
  inset: 0%;
}

.modal-section.hide {
  display: none;
}

.modal-content-wrap {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 470px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.text-block-6 {
  color: #fff;
  font-size: 16px;
}

.modal-container-new {
  background-color: var(--white);
  border: 1px solid #edeff3;
  border-radius: 24px;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  position: relative;
  overflow: hidden;
}

.modal-container-new.first-layer {
  /* background-color: var(--light-mint); */
  background-color: var(--white);
  max-width: 900px;
  height: auto;
  padding-bottom: 80px;
  overflow: visible;
}

.modal-container-new.first-layer.light-mint {
  background-color: var(--light-mint);
}

.modal-container-new.second-layer {
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: auto;
  margin-bottom: -40px;
  padding-left: 60px;
  padding-right: 60px;
}

.modal-image {
  width: 400px;
  height: 400px;
}

.modal-grid-system {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-rows: auto auto;
  align-items: end;
}

.success-message-3 {
  background-color: var(--turqoise);
  color: var(--white);
  border-radius: 16px;
}

.submit-button-3 {
  background-color: #5eb8b8;
  padding: 24px 36px;
  font-size: 18px;
  transition: background-color .3s;
}

.submit-button-3:hover {
  background-color: #46a0a0;
}

.modal-container-2 {
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 840px;
  margin-left: auto;
  margin-right: auto;
  padding: 100px 5% 68px;
  position: relative;
}

.modal-heading-2 {
  color: #000;
  letter-spacing: -.03em;
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 50px;
  line-height: 56px;
}

.modal-section-2 {
  background-color: #d1ebea;
  padding: 100px 3%;
}

.modal-content-wrap-2 {
  text-align: center;
  flex-direction: column;
  align-items: center;
  max-width: 470px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.list-of-areas {
  background-color: var(--light-cream);
}

.modal-close-button {
  color: var(--turqoise);
  cursor: pointer;
  align-self: center;
  padding: 10px;
  transition: all .2s ease-in-out;
  position: absolute;
  inset: 10px 10px auto auto;
  z-index: 9999;
}

.modal-close-button:hover {
  color: var(--dark-blue);
  transform: scale(1.08);
}

.x-button {
  font-family: "Fa Solid 900", sans-serif;
  font-size: 28px;
}

.x-button.dark {
  line-height: 1;
}

.x-button.white {
  color: var(--white);
}

.x-button.turqoise {
  color: var(--turqoise);
}

.x-button.turqoise._48px {
  font-size: 48px;
  line-height: 48px;
}

.x-button.brown {
  color: #8c8679;
}

.x-button.yellow {
  color: #f1a647;
}

.x-button.xsmall {
  font-size: 12px;
}

.x-button.small {
  font-size: 22px;
}

@media screen and (max-width: 991px) {
  .navbar-container {
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
  }

  .navbar-logo {
    max-width: none;
  }

  .investment-office-logo {
    max-width: 180px;
  }

  .nav-button {
    color: var(--white);
    background-color: #ffffff1a;
    border-radius: 8px;
    margin-left: 0;
    margin-right: 0;
    padding: 20px 24px;
    font-size: 14px;
    display: flex;
  }

  .nav-button:hover {
    color: var(--turqoise);
    transform: none;
  }

  .description {
    font-size: 16px;
    line-height: 1.6;
  }

  .hero-section {
    padding-top: 140px;
  }

  .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .heading-set-3 {
    text-align: left;
  }

  .limited-heading-set {
    z-index: 3;
    position: relative;
  }

  .cover-image.absolute {
    top: -41%;
    right: -3%;
  }

  .cover-image.insights {
    top: auto;
  }

  .cover-visual {
    width: 400px;
    height: 400px;
  }

  .cover-visual.insights {
    width: auto;
    height: auto;
  }

  .h3-heading.middle.light {
    align-self: center;
  }

  .content-block {
    max-width: none;
  }

  .section-large.dark-blue {
    overflow: hidden;
  }

  .section-large.infographics {
    background-image: linear-gradient(180deg, var(--light-cream), #f9f7f780 16%, #f9f7f780 53%, var(--light-cream)), url('/images/FDI-Strategy-Design-Guideline-Map_1.webp'), linear-gradient(to bottom, transparent, transparent);
    background-position: 0 0, 300% 0, 0 0;
    background-size: auto, auto 100%, auto;
  }

  .section-large.turqoise {
    overflow: hidden;
  }

  .content-wrapper {
    align-items: flex-start;
  }

  .content-grid-b {
    grid-template-columns: 1fr;
  }

  .block {
    top: 28%;
  }

  .container-2.cc-flex-h {
    flex-direction: column;
  }

  .col-right {
    align-self: stretch;
    margin-top: 24px;
  }

  .index__item {
    height: 3.95rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .h4-heading {
    font-size: 24px;
  }

  .h4-heading.light {
    font-size: 18px;
  }

  .middle-heading-set {
    z-index: 3;
    position: relative;
  }

  .dot-block {
    width: 24px;
  }

  .clone-section {
    margin-bottom: 20vh;
  }

  .timeline__year {
    text-align: center;
    font-size: 150px;
  }

  .section-2 {
    padding-left: 24px;
    padding-right: 24px;
  }

  .timeline__col.mod--1 {
    align-items: center;
    margin-bottom: 32px;
    display: flex;
  }

  .grid {
    grid-column-gap: 24px;
  }

  .grid.mod--timeline2 {
    grid-template-rows: auto auto;
  }

  .timeline__sticky {
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .swiper.swiper--reviews-numb {
    max-width: 440px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .swiper.swiper--reviews {
    width: 100%;
    max-height: 470px;
    margin-bottom: 0;
  }

  .lines__line.mod--timeline-2 {
    width: 610px;
  }

  .timeline__ico {
    margin-bottom: 0;
    margin-right: 24px;
  }

  .heading-wrap {
    flex-flow: row;
    align-items: flex-start;
  }

  .heading-wrap.m0-0 {
    flex-flow: row;
  }

  .testimonial-slider.wide,
  .testimonial-slide.wide {
    width: 550px;
  }

  .content-feature-item {
    flex: 1;
    max-width: 300px;
  }

  .content-mockup-wrap {
    flex: 1;
  }

  .content-main-wrap {
    grid-column-gap: 18px;
    grid-row-gap: 18px;
    flex-flow: wrap;
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .content-features-column {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    flex-direction: row;
    max-width: 300px;
    display: flex;
  }

  .infographic-content-grid {
    grid-template-columns: 1fr;
  }

  .bento-card-header {
    max-height: none;
  }

  .bento-grid {
    flex-flow: column;
    grid-template-columns: 1fr;
    display: flex;
  }

  .bento-grid._6-columns {
    flex-flow: column;
    display: flex;
  }

  .tab-image {
    width: 100%;
  }

  .tab-link-text-wrapper {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }

  .paragraph-small-2.text-color-gray-800.mobile-none {
    display: none;
  }

  .tabs-component {
    flex-direction: column;
  }

  .tabs-component.reverted.limited {
    flex-flow: column;
  }

  .tab-link-horizontal {
    justify-content: space-between;
    align-items: flex-end;
  }

  .tabs-menu {
    flex-flow: row;
    justify-content: space-between;
    align-items: flex-end;
    max-width: none;
    margin-bottom: 32px;
    margin-left: 0%;
  }

  .tabs-menu.reverted {
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    max-width: none;
    display: grid;
  }

  .testimonial-mask {
    max-width: none;
    height: auto;
  }

  .paragraph-regular {
    line-height: 1.4;
  }

  .key-outcome-slider {
    align-self: center;
    width: 100%;
  }

  .testimonial-grid {
    flex-flow: column;
    display: flex;
  }

  .testimonial-content-wrapper {
    display: none;
  }

  .infographic-card {
    flex-flow: column;
    width: 600px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .section-key-outcomes {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .footer-link-wrapper {
    flex-wrap: wrap;
  }

  .section-footer {
    padding-bottom: 140px;
  }

  .nav-button-2 {
    margin-left: 0;
    margin-right: 0;
  }

  .sticky-section,
  .sticky-section.sticky-160 {
    position: sticky;
  }

  .first-layer {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    height: auto;
    margin-bottom: -20px;
    margin-left: 60px;
    margin-right: 60px;
    padding: 60px;
  }

  .overlapping-section {
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
  }

  .overlapping-section.mobile-clear {
    padding-left: 0;
    padding-right: 0;
  }

  .second-layer {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    height: auto;
    margin-bottom: -20px;
    margin-left: 60px;
    margin-right: 60px;
    padding: 60px;
  }

  .entrance-message {
    padding-top: 0;
  }

  .overlapping-bento.first-layer,
  .overlapping-bento.second-layer {
    margin-left: 30px;
    margin-right: 30px;
  }

  .overlapping-grid-system {
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    align-self: stretch;
  }

  .number-display.turqoise.left._120px {
    font-size: 80px;
  }

  .fdi-targets-grid {
    grid-column-gap: 9px;
    grid-row-gap: 16px;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto auto;
    grid-auto-columns: 1fr;
    width: auto;
    display: grid;
  }

  .radial-grid-system {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    flex-flow: wrap;
    display: flex;
  }

  .h5-heading {
    font-size: 20px;
  }

  .generic-slider-trigger {
    width: 20%;
  }

  .team-slider-mask {
    width: 550px;
  }

  .mockup-holder {
    width: 300px;
    height: auto;
  }

  .mockup-image {
    position: relative;
  }

  .button-left-icon {
    height: 40px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .content-tab-link-2 {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    flex-flow: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 24px 5% 16px;
  }

  .content-tabs-menu {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .index-item-wrapper {
    overflow: auto;
  }

  .index-chapter {
    flex: none;
  }

  .index-item {
    flex: none;
    padding-left: 20px;
    padding-right: 20px;
  }

  .policy-column-holder {
    flex-flow: column;
    position: relative;
  }

  .policy-column-1,
  .policy-column-2 {
    width: auto;
  }

  .policy-area-card.light {
    height: 240px;
    padding-bottom: 30px;
  }

  .faq-container {
    flex: 1;
  }

  .faq-section {
    padding-top: 80px;
  }

  .policy-area-visual {
    width: auto;
    height: 100%;
    top: 0;
    right: -50px;
  }

  .footer-top-row,
  .footer-center {
    grid-row-gap: 24px;
    flex-direction: column;
    align-items: center;
  }

  .navigation-logo {
    height: 32px;
  }

  .keyfindings-card {
    height: 650px;
  }

  .section-intro {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .hamburger {
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    display: none;
  }

  .dropdown-toggle {
    display: inline-flex;
  }

  .dropdown-list.w--open {
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background-color: #ddd3;
    justify-content: space-between;
    align-items: center;
    height: 400px;
    display: flex;
    position: fixed;
  }

  .dropdown-button {
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    display: block;
  }

  .a-filter-dropdown-large {
    max-width: 100%;
  }

  .a-filter-form-top {
    flex-flow: column;
  }

  .a-filter-search {
    align-self: stretch;
  }

  .a-filter-dropdown-small {
    max-width: 100%;
  }

  .a-filter-dropdown-wrapper {
    align-self: stretch;
  }

  .filtering-container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .filtering-heading-set {
    z-index: 3;
    max-width: 480px;
    position: relative;
  }

  .insights-entrance {
    padding-top: 0;
    padding-bottom: 40px;
  }

  .menu-button {
    border-radius: 4px;
    padding: 10px;
  }

  .menu-button.w--open {
    background-color: var(--dark-blue);
    color: var(--turqoise);
    border-radius: 8px;
  }

  .nav-menu {
    background-color: var(--dark-blue);
    border-bottom: 1px solid #d6d3d1;
    padding: 16px 5%;
  }

  .nav-menu.hide-tablet {
    display: block;
  }

  .nav-links-list {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    background-color: #0000;
    flex-flow: column;
    justify-content: center;
    align-items: stretch;
    width: 100%;
    display: flex;
  }

  .nav-container {
    justify-content: space-between;
    align-items: center;
  }

  .nav-menu-grid {
    grid-column-gap: 80px;
    grid-row-gap: 80px;
    flex-flow: column;
    grid-template-rows: 400px 400px;
    grid-template-columns: 1fr;
    display: flex;
  }

  .nav-logo-link {
    justify-content: flex-start;
    align-items: center;
  }

  .nav-logo-link.hide-tablet {
    display: none;
  }

  .nav-logo-link.hide-desktop {
    align-items: stretch;
    display: flex;
  }

  .nav-content-left,
  .nav-divider {
    display: none;
  }

  .nav-divider.full-height {
    width: 1px;
    display: flex;
  }

  .nav-dropdown-content {
    flex-flow: column;
    grid-template-columns: 1fr;
  }

  .nav-dropdown-list-medium {
    padding: 0;
  }

  .nav-button-group {
    display: none;
  }

  .nav-content-right {
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    flex-flow: column;
    order: -1;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
    display: flex;
  }

  .nav-dropdown-list.w--open {
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
  }

  .navbar-mobile-button-set {
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    justify-content: space-between;
    align-self: center;
    align-items: center;
    display: flex;
  }

  .download-button-mobile-nav {
    display: block;
  }

  .search-bar-container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .footer-grid {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    grid-template-columns: 2fr 1fr;
  }

  .footer-links-grid-small {
    grid-auto-columns: 1fr;
  }

  .footer-legal-wrap-2 {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    flex-direction: column;
    align-items: center;
  }

  .footer-link-badge {
    align-items: center;
  }

  .section-legal-dark {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .footer-heading {
    color: var(--white);
    border-radius: 8px;
    margin-left: 0;
    margin-right: 0;
    font-size: 14px;
    display: flex;
  }

  .footer-heading:hover {
    color: var(--turqoise);
    transform: none;
  }

  .report-visual-footer {
    position: relative;
  }

  .modal-section {
    justify-content: center;
    align-items: flex-end;
  }

  .modal-container-new.first-layer,
  .modal-container-new.second-layer {
    margin-left: 30px;
    margin-right: 30px;
  }

  .modal-image {
    width: 400px;
    height: 400px;
  }

  .modal-image.insights {
    width: auto;
    height: auto;
  }

  .modal-grid-system {
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    align-self: stretch;
  }
}

@media screen and (max-width: 767px) {
  .heading-1 {
    font-size: 50px;
    line-height: 60px;
  }

  .description {
    font-size: 16px;
    line-height: 28px;
  }

  .hero-section {
    padding-top: 60px;
  }

  .cover-image.absolute {
    top: -50%;
  }

  .cover-image.absolute.modal {
    position: absolute;
    top: -100px;
    right: 0;
  }

  .cover-image.insights {
    top: 140px;
  }

  .cover-visual {
    width: 320px;
    height: 320px;
  }

  .h3-heading {
    font-size: 28px;
  }

  .section-large {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  .section-large.dark-blue {
    padding-left: 20px;
    padding-right: 20px;
  }

  .content-grid-b {
    grid-template-columns: 1fr;
  }

  .block {
    margin-bottom: 40px;
  }

  .container-2 {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }

  .container-2.cc-flex-h {
    flex-direction: column;
  }

  .h4-heading,
  .h4-heading.light {
    font-size: 22px;
    line-height: 1.3;
  }

  .paragraph-x-large {
    font-size: 19px;
    line-height: 1.3;
  }

  .dot-block {
    order: 2;
    align-self: center;
    min-height: 10vh;
  }

  .step-content-block {
    z-index: 2;
    order: -1;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .scroll-line-wrap {
    z-index: 1;
    margin-bottom: -3px;
    bottom: 50%;
  }

  .step {
    justify-content: center;
    padding: 8%;
  }

  .content-wrap {
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 40vh;
    padding-bottom: 20px;
  }

  .img-block {
    z-index: 3;
    order: 3;
    min-height: 40vh;
    max-height: 40vh;
    padding-top: 20px;
    position: relative;
  }

  .img-block.anima-1 {
    max-width: 90%;
  }

  .scroll-base {
    position: relative;
  }

  .dot {
    z-index: 99;
    position: static;
  }

  .step-block-2 {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    min-height: 110vh;
  }

  .timeline__year {
    font-size: 80px;
  }

  .section-2 {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .section-2.section--timeline {
    padding-top: 0;
    padding-bottom: 0;
  }

  .timeline__ico-title {
    font-size: 18px;
  }

  .btn-tag-2 {
    min-width: auto;
    margin-left: 8px;
    margin-right: 8px;
    padding-left: 12px;
    padding-right: 16px;
  }

  .timeline__col.mod--1 {
    align-items: center;
    padding-top: 0;
    display: flex;
  }

  .grid.mod--timeline2 {
    grid-template-rows: auto auto;
  }

  .grid.mod--timeline {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .lines {
    width: 24px;
    height: 24px;
  }

  .lines.mod--timeline {
    left: auto;
    right: 32px;
  }

  .swiper-slide {
    width: 100%;
  }

  .swiper-slide.slide--reviews {
    flex-direction: column;
    padding-top: 32px;
  }

  .swiper.swiper--reviews-numb {
    max-width: 195px;
  }

  .swiper.swiper--reviews {
    max-height: 440px;
  }

  .lines__line {
    bottom: 12px;
    right: 12px;
  }

  .lines__line.mod--timeline-1 {
    width: 680px;
  }

  .lines__line.mod--timeline-2 {
    width: 40px;
  }

  .timeline__ico {
    flex: none;
    max-width: 64px;
    margin-bottom: 0;
    margin-right: 24px;
  }

  .timeline__p {
    font-size: 18px;
  }

  .timeline__tags {
    margin-top: 32px;
  }

  .section-regular {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  .testimonial-slider.wide,
  .testimonial-slide.wide {
    width: 400px;
  }

  .content-feature-item {
    flex: 0 auto;
  }

  .content-main-wrap {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }

  .content-features-column {
    flex-direction: column;
    max-width: 200px;
  }

  .full-width-slider-section {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  .infographic-content-grid {
    grid-template-columns: 1fr;
  }

  .h2-heading.middle {
    font-size: 42px;
    line-height: 1.2;
  }

  .tab-link-text-wrapper {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }

  .paragraph-large {
    font-size: 18px;
    line-height: 1.5;
  }

  .testimonial-grid {
    grid-template-columns: 1fr;
  }

  .section-key-outcomes {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  .footer-wrapper {
    margin-bottom: 60px;
  }

  .first-layer {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    margin-left: 0;
    margin-right: 0;
    padding: 30px;
  }

  .overlapping-section {
    padding-left: 30px;
    padding-right: 30px;
  }

  .overlapping-section.mobile-clear {
    padding-left: 0;
    padding-right: 0;
  }

  .second-layer {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    margin-left: 0;
    margin-right: 0;
    padding: 30px;
  }

  .entrance-message {
    padding-top: 100px;
  }

  .overlapping-bento.first-layer,
  .overlapping-bento.second-layer {
    margin-bottom: -20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .overlapping-grid-system {
    grid-column-gap: 18px;
    grid-row-gap: 18px;
  }

  .number-display {
    font-size: 80px;
  }

  .math-sign {
    font-size: 40px;
  }

  .fdi-targets-grid {
    grid-template-columns: auto auto auto;
  }

  .h5-heading {
    font-size: 21px;
  }

  .h5-heading.light {
    font-size: 18px;
  }

  .team-slider {
    padding-top: 0;
  }

  .fdi-slider-section {
    padding-top: 72px;
    padding-bottom: 0;
  }

  .team-slide {
    margin-right: 16px;
  }

  .generic-slider-trigger {
    display: none !important;
    background-image: none;
    width: 10%;
  }

  .generic-slider-trigger.left {
    background-image: none;
  }

  .generic-slider-trigger.left.dark-blue {
    background-image: none;
    width: 50%;
    height: 25%;
    inset: auto auto 0% 0%;
  }

  .generic-slider-trigger.right.dark-blue {
    background-image: none;
    justify-content: flex-end;
    align-items: center;
    width: 50%;
    height: 25%;
    inset: auto 0% 0% auto;
  }

  .team-slider-mask {
    width: 80%;
  }

  .full-width-container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .content-tab-link-2 {
    padding-top: 24px;
    padding-bottom: 12px;
  }

  .content-tab-link-2.w--current {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .content-tabs-menu {
    flex-flow: column;
    display: flex;
  }

  .hero-grid {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    flex-flow: column;
    grid-template-rows: auto;
    grid-template-columns: 2fr;
    grid-auto-columns: 1fr;
    grid-auto-flow: row;
    display: grid;
  }

  .mobile-none {
    display: none;
  }

  .index-item-wrapper {
    overflow: auto;
  }

  .index-chapter {
    flex: none;
    padding-left: 20px;
    padding-right: 20px;
  }

  .icon-link {
    padding: 8px;
  }

  .index-item {
    letter-spacing: 0;
    flex: none;
  }

  .faq-section {
    background-image: none;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .action-plan-card-heading {
    font-size: 36px;
  }

  .footer-link-wrap {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
  }

  .footer-bottom {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    flex-direction: column-reverse;
  }

  .footer-divider-small {
    display: none;
  }

  .content-card {
    padding: 48px 32px 32px;
  }

  .navigation-logo.secondary {
    display: none;
  }

  .keyfindings-card {
    padding-left: 0;
    padding-right: 0;
  }

  .header-row {
    padding-left: 20px;
    padding-right: 20px;
  }

  .card-visual {
    left: -110px;
  }

  .section-intro {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  .section-intro-header {
    padding-left: 20px;
    padding-right: 20px;
  }

  .qfdi-card-heading {
    font-size: 50px;
    line-height: 60px;
  }

  .a-filter-radio.jetboost-list-search-reset-xr5q {
    width: auto;
    padding-right: 16px;
  }

  .a-filter-dropdown-wrapper {
    flex-flow: row;
  }

  .filtering-number-display {
    font-size: 80px;
  }

  .insights-entrance {
    padding-top: 100px;
    padding-bottom: 0;
  }

  .nav-logo-link.hide-desktop {
    grid-column-gap: 4px;
    grid-row-gap: 4px;
  }

  .sticky-search-bar {
    top: 80px;
  }

  .generic-policy-group-card {
    padding-left: 20px;
    padding-right: 20px;
  }

  .full-wrap-category-holder {
    padding-left: 20px;
  }

  .footer-grid {
    grid-template-columns: 1fr;
  }

  .footer-legal-wrap-2 {
    grid-template-columns: 1fr 1fr;
  }

  .section-legal-dark {
    padding-bottom: 32px;
  }

  .footer-sub-link.main.right {
    justify-content: center;
    align-items: center;
  }

  .section-regular-2 {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  .modal-container-new.first-layer,
  .modal-container-new.second-layer {
    margin-bottom: -20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .modal-image {
    width: 280px;
    height: 280px;
  }

  .modal-grid-system {
    grid-column-gap: 18px;
    grid-row-gap: 18px;
  }
}

@media screen and (max-width: 479px) {
  .navbar {
    padding-top: 0;
  }

  .navbar-container {
    padding: 4px 10px;
  }

  .navbar-home {
    flex: 1;
  }

  .navbar-logo {
    width: 100px;
    height: auto;
  }

  .investment-office-logo {
    padding-left: 10px;
    display: none;
  }

  .nav-button {
    padding: 10px;
    font-size: 14px;
  }

  .heading-1 {
    font-size: 32px;
    line-height: 40px;
  }

  .description {
    font-size: 16px;
    line-height: 28px;
  }

  .hero-section {
    background-image: radial-gradient(circle farthest-corner at 100% 50%, #87a4a9, #d3dfdf00 44%), linear-gradient(180deg, var(--light-mint), #aecacc 49%, #d8e4e3);
    height: auto;
    padding-top: 100px;
  }

  .container {
    padding-left: 20px;
    padding-right: 20px;
    overflow: hidden;
  }

  .body {
    background-color: var(--light-mint);
  }

  .cover-image.absolute {
    top: -220px;
  }

  .cover-image.absolute.modal {
    position: absolute;
    top: -246px;
  }

  .cover-image.insights {
    top: 0;
  }

  .cover-visual {
    width: 300px;
    height: 300px;
  }

  .content-block {
    min-height: auto;
    padding: 24px;
  }

  .content-feature-grid {
    grid-template-columns: 1fr;
  }

  .section-large.infographics {
    background-image: linear-gradient(180deg, var(--light-cream), #f9f7f736 16%, #f9f7f700 53%, var(--light-cream)), url('/images/FDI-Strategy-Design-Guideline-Map_1.webp'), linear-gradient(to bottom, transparent, transparent), linear-gradient(to bottom, var(--light-cream), var(--light-cream));
    background-position: 0 0, 100% -150px, 0 0, 0 0;
    background-repeat: repeat, no-repeat, repeat, repeat;
    background-size: auto, 100%, auto, auto;
    background-attachment: scroll, scroll, scroll, scroll;
  }

  .block {
    padding: 28px 32px 32px;
    font-size: 24px;
    top: 24%;
  }

  .block._3 {
    top: 28%;
  }

  .block._2 {
    top: 24%;
  }

  .block._1 {
    top: 20%;
  }

  .container-2 {
    padding-left: 28px;
    padding-right: 28px;
    overflow: visible;
  }

  .h4-heading {
    font-size: 21px;
  }

  .img-block {
    width: 90%;
  }

  .content {
    font-size: 20px;
  }

  .btn-tag-2 {
    margin-left: 0;
    margin-right: 16px;
  }

  .swiper-slide,
  .swiper.swiper--reviews-numb {
    width: 100%;
  }

  .swiper.swiper--reviews {
    width: 100%;
    max-height: 560px;
  }

  .section-regular.background-cream {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .section-regular.background-cream.no-padding-mobile {
    padding-left: 0%;
    padding-right: 0%;
  }

  .heading-wrap {
    flex-flow: column;
    margin-bottom: 0;
  }

  .testimonial-slider.wide,
  .testimonial-slide.wide {
    width: auto;
    padding-bottom: 0;
  }

  .flipcard-front {
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
  }

  .flipcard-front.light,
  .flipcard-fdi-target-back {
    padding-left: 20px;
    padding-right: 20px;
  }

  .flipping-card-image {
    display: block;
  }

  .content-mockup-wrap {
    min-width: auto;
    padding-left: 0;
    padding-right: 0;
  }

  .content-main-wrap {
    flex-flow: column;
    grid-row-gap: 40px;
  }

  .tab-link,
  .tab-link.w--current {
    flex: none;
    width: auto;
  }

  .bento-card-header {
    max-height: none;
    padding-left: 16px;
    padding-right: 16px;
  }

  .paragraph-x-large.text-color-gray-600 {
    font-size: 15px;
    line-height: 1.4;
  }

  .bento-grid {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    flex-flow: column;
    display: flex;
  }

  .bullets-with-icons {
    grid-template-columns: 1fr;
  }

  .tab-image {
    width: 300px;
  }

  .h2-heading.middle {
    font-size: 36px;
  }

  .paragraph-small-2.text-color-gray-800 {
    display: none;
  }

  .tabs-component {
    padding-top: 40px;
  }

  .tabs-component.reverted.limited {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    padding-top: 16px;
  }

  .tab-link-horizontal {
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    background-color: var(--white);
    border-radius: 16px;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 20px;
    display: block;
  }

  .tabs-menu {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    overflow: auto;
  }

  .tabs-menu.reverted {
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    display: flex;
  }

  .h6-heading {
    font-size: 18px;
    line-height: 1.4;
  }

  .h6-heading.turqoise.right-aligned {
    overflow-wrap: break-word;
  }

  .testimonial-mask {
    align-self: center;
    width: 100vw;
  }

  .key-info-div {
    align-self: stretch;
    min-height: auto;
  }

  .key-outcome-slider {
    max-width: none;
    display: flex;
  }

  .infographics-slide {
    vertical-align: middle;
    margin-right: 0;
    padding-left: 20px;
    padding-right: 20px;
  }

  .paragraph-large.limited {
    width: auto;
    font-size: 16px;
  }

  .paragraph-large.limited.bold {
    font-weight: 600;
    line-height: 1.6;
  }

  .infographic-card {
    width: auto;
    padding: 18px;
  }

  .section-key-outcomes {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .number-fact.mid.bold {
    font-size: 24px;
    line-height: 1.2;
  }

  .footer-link {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .footer-logo-wrapper {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    flex-direction: column;
  }

  .section-footer {
    padding-bottom: 100px;
  }

  .nav-button-2 {
    padding: 10px;
    font-size: 14px;
  }

  .overlapping-section {
    padding-left: 10px;
    padding-right: 10px;
  }

  .entrance-message {
    height: 520px;
    padding-top: 20px;
  }

  .entrance-message.is-empty {
    display: none;
  }

  .number-display.turqoise.left._120px {
    font-size: 60px;
  }

  .fdi-targets-grid {
    grid-template-columns: auto auto;
  }

  .mini-card-title {
    font-size: 16px;
    line-height: 1.2;
  }

  .radial-card {
    border-width: 6px;
    width: 140px;
    height: 140px;
  }

  .h5-heading.light {
    font-size: 16px;
  }

  .team-slider {
    padding-top: 80px;
  }

  .generic-slider-trigger {
    display: none;
  }

  .generic-slider-trigger.left.dark-blue {
    background-image: none;
    width: 50%;
    height: 25%;
    inset: auto auto 0% 0%;
  }

  .generic-slider-trigger.left.cream {
    background-image: none;
    justify-content: flex-start;
    align-items: flex-end;
    width: 50%;
    height: 60px;
    padding-left: 5%;
    inset: auto auto 0% 0%;
  }

  .generic-slider-trigger.right.dark-blue {
    z-index: 1;
    background-image: none;
    width: 50%;
    height: 25%;
    display: flex;
    inset: auto 0% 0% auto;
  }

  .generic-slider-trigger.right.cream {
    background-image: none;
    justify-content: flex-end;
    align-items: flex-end;
    width: 50%;
    height: 60px;
    padding-right: 5%;
    display: flex;
    inset: auto 0% 0% auto;
  }

  .team-slider-mask,
  .testimonial-slider .team-slider-mask {
    width: 90%;
  }

  .quality-fdi-visual {
    position: relative;
    top: 50px;
    right: -50px;
  }

  .full-width-container {
    padding-bottom: 0;
  }

  .mockup-holder {
    width: 320px;
    height: auto;
  }

  .mockup-image {
    position: relative;
  }

  .button-left-icon {
    align-self: stretch;
    height: 40px;
    padding: 4px 10px;
    font-size: 12px;
  }

  .button-icon-left {
    font-size: 16px;
  }

  .content-tab-link-2 {
    flex: none;
  }

  .content-tabs-menu {
    flex-flow: row;
    overflow: auto;
  }

  .hero-grid {
    flex-flow: column;
    display: flex;
  }

  .index-wrapper {
    width: 100vw;
  }

  .index-item-wrapper {
    box-sizing: content-box;
    display: flex;
    overflow: auto;
  }

  .index-chapter {
    flex: none;
    padding-left: 20px;
    padding-right: 20px;
  }

  .icon-link {
    padding-left: 10px;
    padding-right: 10px;
  }

  .index-item {
    letter-spacing: 0;
    flex: none;
    padding-top: 9px;
    font-size: 12px;
    line-height: 1.2;
  }

  .index {
    flex-flow: row;
    align-self: flex-start;
    width: auto;
    padding-left: 0;
    padding-right: 0;
    left: -10px;
    overflow: visible;
  }

  .contact-trigger {
    width: 40px;
  }

  .contact-block {
    align-self: flex-end;
    padding-right: 40px;
  }

  .action-plans-section {
    padding-left: 0%;
    padding-right: 0%;
  }

  .policy-grid {
    margin-left: 20px;
    margin-right: 20px;
  }

  .policy-area-card.light {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    padding: 30px 20px 20px;
  }

  .faq-container {
    max-width: none;
  }

  .faq-icon-circle {
    width: 30px;
    height: 30px;
  }

  .faq-section {
    padding-top: 0;
    padding-bottom: 0;
  }

  .faq-item {
    border-radius: 0;
  }

  .faq-title-wrapper {
    flex-flow: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: auto;
    padding-left: 12px;
    padding-right: 12px;
  }

  .faq-wrapper {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .related-partners-grid {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    flex-flow: column;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: flex;
  }

  .micro-heading {
    font-size: 14px;
  }

  .policy-area-visual {
    position: absolute;
    inset: 0% -80px 0% auto;
  }

  .footer-link-wrap {
    flex-direction: column;
    grid-template-columns: 1fr;
  }

  .content-statistics {
    display: flex;
  }

  .content-card {
    padding: 40px 0 24px;
  }

  .content-pane-wrapper {
    padding-left: 5%;
    padding-right: 5%;
  }

  .navigation-logo.primary {
    display: inline-block;
  }

  .keyfindings-text-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .keyfindings-card {
    height: auto;
    padding: 20px 20px 40px;
  }

  .keyfinding-visual-holder {
    flex: 1;
    display: flex;
    position: relative;
    bottom: auto;
    left: auto;
  }

  .header-row {
    width: 100%;
  }

  .card-visual {
    width: 290px;
    max-width: none;
    height: auto;
    inset: -45% auto auto -49%;
  }

  .section-intro-header {
    padding-left: 0;
    padding-right: 0;
  }

  .slider-chevron {
    font-size: 24px;
  }

  .qfdi-card-heading {
    font-size: 32px;
    line-height: 40px;
  }

  .action-side {
    align-self: stretch;
  }

  .hamburger {
    padding: 0 10px;
    font-size: 14px;
    display: flex;
  }

  .dropdown-toggle {
    padding: 0;
  }

  .dropdown-list {
    inset: 0% 0% auto;
  }

  .dropdown-list.w--open {
    z-index: 10;
    background-color: var(--light-cream);
    flex-flow: column;
    height: auto;
    inset: 97% 0% 0%;
  }

  .dropdown-button {
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background-color: #f9f7f766;
    align-self: stretch;
    padding: 10px;
    font-size: 14px;
  }

  .a-filter-radio.jetboost-list-search-reset-xr5q {
    width: 80px;
  }

  .a-subheading-regular {
    font-size: 14px;
  }

  .a-filter-dropdown-wrapper {
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    grid-template-rows: auto;
    grid-template-columns: 2fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }

  .a-filter-search-text-field.jetboost-list-search-input-xr5q {
    font-size: 16px;
  }

  .a-filter-layout-icon-wrapper.a-active {
    width: 16px;
    height: 16px;
  }

  .mini-filtering-card:hover {
    transform: none;
    box-shadow: 1px 1px 20px 1px #00000017;
  }

  .filtering-title {
    font-size: 16px;
  }

  .collection-item {
    width: 200px;
  }

  .insights-entrance {
    height: 520px;
    padding-top: 40px;
  }

  .nav-content-left,
  .nav-divider,
  .nav-button-group {
    display: none;
  }

  .nav-content-right {
    display: flex;
  }

  .search-nav {
    flex-flow: row;
    align-self: flex-start;
    width: auto;
    padding-left: 0;
    padding-right: 0;
    overflow: visible;
  }

  .sticky-search-bar {
    top: 0;
  }

  .generic-policy-group-card {
    padding: 20px 0 0;
  }

  .full-wrap-search-holder {
    background-image: linear-gradient(180deg, var(--light-cream) 39%, #cfe0e100);
  }

  .full-wrap-category-holder {
    padding-left: 5%;
    overflow: auto;
  }

  .footer-links-grid-small {
    grid-template-columns: 1fr;
  }

  .submit-button.small {
    text-align: center;
  }

  .footer-legal-wrap-2 {
    align-items: center;
  }

  .legal-links-small {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    flex-direction: column;
  }

  .footer-heading {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    font-size: 14px;
  }

  .report-visual-footer {
    position: relative;
  }

  .full-width-tab-holder {
    padding-top: 40px;
  }

  .policy-area-card-title {
    max-width: 16ch;
    font-size: 24px;
  }

  .form {
    border-radius: 0;
    flex-direction: column;
  }

  .submit-button-2 {
    border-radius: 10px;
  }

  .form-block-modal {
    height: auto;
  }

  .email-field {
    border-radius: 10px;
    margin-bottom: 8px;
  }

  .modal-section {
    flex-flow: column;
    justify-content: flex-end;
    align-items: stretch;
    padding: 0 0% 10vh;
  }

  .modal-container-new.first-layer {
    align-self: stretch;
    margin-left: 20px;
    margin-right: 20px;
  }

  .modal-image {
    width: 320px;
    height: auto;
  }

  .modal-grid-system {
    flex-flow: column;
    display: flex;
  }

  .submit-button-3 {
    border-radius: 10px;
  }
}

#w-node-b086740a-6453-2282-5e13-7eae65a1f488-9d204e45,
#w-node-_5586ce51-3a77-fc5d-ab77-ce7bf620c902-9d204e45,
#w-node-e66f80ca-6b5d-1ff2-024e-d430453c81f9-9d204e45 {
  align-self: end;
}

#w-node-_272f86e5-c637-990a-af5b-9b940c9f414c-9d204e45 {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-dbdb231f-3721-ba01-b35b-fe8525f08cf5-9d204e45 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_8de50c2f-4414-9316-7bae-0b3c7a75c0a4-9d204e45 {
  grid-area: span 1 / span 4 / span 1 / span 4;
}

#w-node-_3cb4207e-aef5-b5ab-6667-e33bc628ab5d-9d204e45 {
  grid-area: span 1 / span 3 / span 1 / span 3;
}

#w-node-dd0b2880-ca5c-30d7-97f9-e9d606a5c1fb-9d204e45 {
  grid-area: span 1 / span 5 / span 1 / span 5;
}

#w-node-_8de50c2f-4414-9316-7bae-0b3c7a75c0b1-9d204e45 {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-_3b2fafe6-098a-7068-df95-95bf8d44ff90-9d204e45 {
  grid-area: span 1 / span 3 / span 1 / span 3;
}

#w-node-_3b2fafe6-098a-7068-df95-95bf8d44ff9d-9d204e45 {
  grid-area: span 1 / span 4 / span 1 / span 4;
}

#w-node-_3b2fafe6-098a-7068-df95-95bf8d44ffaa-9d204e45 {
  grid-area: span 1 / span 5 / span 1 / span 5;
}

#w-node-_3b2fafe6-098a-7068-df95-95bf8d44ffdb-9d204e45 {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-_2bda0364-020c-dc46-9fb6-134667d5306a-9d204e45 {
  grid-area: span 1 / span 5 / span 1 / span 5;
}

#w-node-_2bda0364-020c-dc46-9fb6-134667d5309b-9d204e45 {
  grid-area: span 2 / span 2 / span 2 / span 2;
}

#w-node-_6ab104b9-9e8f-71a6-c9ef-3afbc8b2f88e-9d204e45 {
  grid-area: span 1 / span 5 / span 1 / span 5;
}

#w-node-_8d20d751-ba12-8f08-e28f-84feb73dd88b-b73dd887 {
  place-self: start stretch;
}

#w-node-_8d20d751-ba12-8f08-e28f-84feb73dd89c-b73dd887 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: start;
}

#w-node-_8d20d751-ba12-8f08-e28f-84feb73dd89d-b73dd887,
#w-node-_8d20d751-ba12-8f08-e28f-84feb73dd8b1-b73dd887 {
  align-self: start;
}

#w-node-_8d20d751-ba12-8f08-e28f-84feb73dd8c8-b73dd887 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  place-self: stretch end;
}

#w-node-_8d20d751-ba12-8f08-e28f-84feb73dd8ef-b73dd887 {
  justify-self: center;
}

#w-node-_8d20d751-ba12-8f08-e28f-84feb73dd8f2-b73dd887,
#w-node-_8d20d751-ba12-8f08-e28f-84feb73dd8f7-b73dd887 {
  justify-self: end;
}

#w-node-f31e102f-d8e9-e5f8-3a85-91facd5c1cd9-951b493d {
  grid-area: _1-2;
}

#w-node-aa06b421-a47c-8074-6cb5-f60b951b4953-951b493d {
  grid-area: _2-2;
}

#w-node-aa06b421-a47c-8074-6cb5-f60b951b4972-951b493d {
  grid-area: _2-1;
  justify-self: center;
}

#w-node-aa06b421-a47c-8074-6cb5-f60b951b494a-951b493d {
  grid-area: _3-2;
}

#w-node-aa06b421-a47c-8074-6cb5-f60b951b4969-951b493d {
  grid-area: _2-3;
}

#w-node-aa06b421-a47c-8074-6cb5-f60b951b495f-951b493d {
  grid-area: _1-3;
}

#w-node-c915a300-f121-47df-acce-8975ad92eaec-ad92eaea {
  grid-area: span 1 / span 3 / span 1 / span 3;
}

#w-node-c915a300-f121-47df-acce-8975ad92eaee-ad92eaea {
  grid-area: Area;
}

#w-node-c915a300-f121-47df-acce-8975ad92eaef-ad92eaea {
  grid-area: Area-2;
}

#w-node-c915a300-f121-47df-acce-8975ad92eaf3-ad92eaea {
  grid-area: Area-3;
}

#w-node-c915a300-f121-47df-acce-8975ad92eaf7-ad92eaea {
  grid-area: Area-4;
}

#w-node-c915a300-f121-47df-acce-8975ad92eafb-ad92eaea {
  grid-area: Area-5;
}

#w-node-c915a300-f121-47df-acce-8975ad92eaff-ad92eaea {
  grid-area: Area-6;
}

#w-node-c915a300-f121-47df-acce-8975ad92eb03-ad92eaea {
  grid-area: Heading;
}

#w-node-_3642eec2-12cd-7ce3-aa3d-f89ed0a9a2f5-d0a9a2ce {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-e54c84a8-78cd-4cd5-a2bb-6f9ac0882c6d-932f3934 {
  grid-area: 1 / 2 / 2 / 4;
}

#w-node-e54c84a8-78cd-4cd5-a2bb-6f9ac0882c7e-932f3934 {
  grid-area: 1 / 1 / 2 / 2;
}

#w-node-e54c84a8-78cd-4cd5-a2bb-6f9ac0882c84-932f3934 {
  grid-area: 1 / 2 / 2 / 4;
}

#w-node-e54c84a8-78cd-4cd5-a2bb-6f9ac0882c93-932f3934 {
  grid-area: 1 / 1 / 2 / 2;
}

#w-node-e54c84a8-78cd-4cd5-a2bb-6f9ac0882c99-932f3934 {
  grid-area: 1 / 2 / 2 / 4;
}

#w-node-e54c84a8-78cd-4cd5-a2bb-6f9ac0882ca8-932f3934 {
  grid-area: 1 / 1 / 2 / 2;
}

#w-node-e54c84a8-78cd-4cd5-a2bb-6f9ac0882cae-932f3934 {
  grid-area: 1 / 2 / 2 / 4;
}

#w-node-_35f88ea4-a5e5-6ced-3e9c-b0bb0e534dcc-932f3934,
#w-node-_35f88ea4-a5e5-6ced-3e9c-b0bb0e534dd7-932f3934,
#w-node-_35f88ea4-a5e5-6ced-3e9c-b0bb0e534ddf-932f3934,
#w-node-_35f88ea4-a5e5-6ced-3e9c-b0bb0e534de7-932f3934,
#w-node-_35f88ea4-a5e5-6ced-3e9c-b0bb0e534def-932f3934 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_35f88ea4-a5e5-6ced-3e9c-b0bb0e534df7-932f3934 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_162270ba-4233-e749-d7bc-f003f015d7a6-932f3934,
#w-node-_162270ba-4233-e749-d7bc-f003f015d7b5-932f3934 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-e0012cb9-91d6-20f9-7f4d-392a6107bc00-932f3934 {
  grid-area: span 2 / span 1 / span 2 / span 1;
}

#w-node-e0012cb9-91d6-20f9-7f4d-392a6107bc08-932f3934,
#w-node-e0012cb9-91d6-20f9-7f4d-392a6107bc10-932f3934,
#w-node-e0012cb9-91d6-20f9-7f4d-392a6107bc18-932f3934,
#w-node-e0012cb9-91d6-20f9-7f4d-392a6107bc20-932f3934,
#w-node-e0012cb9-91d6-20f9-7f4d-392a6107bc29-932f3934,
#w-node-e0012cb9-91d6-20f9-7f4d-392a6107bc31-932f3934,
#w-node-e0012cb9-91d6-20f9-7f4d-392a6107bc39-932f3934,
#w-node-e0012cb9-91d6-20f9-7f4d-392a6107bc41-932f3934,
#w-node-e0012cb9-91d6-20f9-7f4d-392a6107bc49-932f3934,
#w-node-e0012cb9-91d6-20f9-7f4d-392a6107bc50-932f3934 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_315b066c-9375-5d43-64fe-99c0825fb648-bda32aac {
  justify-self: end;
}

#w-node-_9f184bbf-542b-c142-fc2b-e1c6cb86f317-bda32aac {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-_9f184bbf-542b-c142-fc2b-e1c6cb86f31e-bda32aac,
#w-node-_9f184bbf-542b-c142-fc2b-e1c6cb86f325-bda32aac,
#w-node-_9f184bbf-542b-c142-fc2b-e1c6cb86f329-bda32aac,
#w-node-_9f184bbf-542b-c142-fc2b-e1c6cb86f331-bda32aac,
#w-node-_9f184bbf-542b-c142-fc2b-e1c6cb86f339-bda32aac,
#w-node-_9f184bbf-542b-c142-fc2b-e1c6cb86f341-bda32aac,
#w-node-_9f184bbf-542b-c142-fc2b-e1c6cb86f349-bda32aac,
#w-node-_9f184bbf-542b-c142-fc2b-e1c6cb86f34d-bda32aac,
#w-node-_9f184bbf-542b-c142-fc2b-e1c6cb86f355-bda32aac,
#w-node-_9f184bbf-542b-c142-fc2b-e1c6cb86f35d-bda32aac,
#w-node-_9f184bbf-542b-c142-fc2b-e1c6cb86f365-bda32aac,
#w-node-_9f184bbf-542b-c142-fc2b-e1c6cb86f36d-bda32aac,
#w-node-_4b22559f-f405-58d6-06d0-a2a1304daeed-bda32aac,
#w-node-_4b22559f-f405-58d6-06d0-a2a1304daef8-bda32aac,
#w-node-_4b22559f-f405-58d6-06d0-a2a1304daf00-bda32aac,
#w-node-_4b22559f-f405-58d6-06d0-a2a1304daf08-bda32aac,
#w-node-_4b22559f-f405-58d6-06d0-a2a1304daf10-bda32aac {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-a6ebe047-e62c-2f05-5b73-de940367235d-bda32aac {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-a6ebe047-e62c-2f05-5b73-de9403672365-bda32aac,
#w-node-_98fdce91-a221-1bc4-6d3d-37bb983c9b54-bda32aac,
#w-node-_98fdce91-a221-1bc4-6d3d-37bb983c9b63-bda32aac,
#w-node-_98fdce91-a221-1bc4-6d3d-37bb983c9b72-bda32aac,
#w-node-_98fdce91-a221-1bc4-6d3d-37bb983c9b87-bda32aac,
#w-node-_330a69f9-c3c3-7b8d-a818-b9162e580f7d-bda32aac,
#w-node-_330a69f9-c3c3-7b8d-a818-b9162e580f8c-bda32aac,
#w-node-a1c8d248-9cd8-03a7-57ee-8971955b7daa-bda32aac,
#w-node-a1c8d248-9cd8-03a7-57ee-8971955b7dc1-bda32aac {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-a3d0e202-8c68-27c1-4f72-91fb61149c02-bda32aac {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-_9597edd6-e4a9-c1c1-94ed-00f8328a69e7-bda32aac {
  grid-area: span 2 / span 1 / span 2 / span 1;
}

#w-node-_9909d3a2-4e8e-300a-e2aa-d73a9ab7a136-bda32aac {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-d16f4fcd-8b7b-f70d-af48-881525d25db5-bda32aac {
  align-self: stretch;
}

#w-node-_920ce171-c107-880f-e275-3450d4bb76d8-bda32aac,
#w-node-_569286b7-76cb-3339-be86-7ec56e19a0f7-bda32aac {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-b7006ced-f850-0673-fd0f-76ed306bf935-bda32aac {
  grid-area: span 2 / span 2 / span 2 / span 2;
}

#w-node-b7006ced-f850-0673-fd0f-76ed306bf93c-bda32aac,
#w-node-c96aa4eb-355c-cee6-1fb6-1d84c63953dc-bda32aac {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_33ca0750-6a3e-5dca-d9d3-4217311f3170-bda32aac,
#w-node-e420dc75-08be-1f26-11e6-acdb03959688-bda32aac,
#w-node-_9d167489-4fae-9d43-6d1a-8502651eee1f-bda32aac,
#w-node-_0a09421c-a7b6-5fd2-8a2c-c560abd8b29d-bda32aac {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-_9b7b5822-da24-24ea-531c-390fe3665ada-bda32aac,
#w-node-_9b7b5822-da24-24ea-531c-390fe3665af0-bda32aac,
#w-node-_9b7b5822-da24-24ea-531c-390fe3665aff-bda32aac {
  align-self: end;
}

#w-node-_9b7b5822-da24-24ea-531c-390fe3665b01-bda32aac {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-_8ef79bb6-13fa-f9cf-d760-5293f3e0cc55-e090c599 {
  align-self: center;
}

#w-node-_8ef79bb6-13fa-f9cf-d760-5293f3e0cc60-e090c599 {
  place-self: center;
}

#w-node-_63b10559-98ee-8c32-b7cf-08c66a8e4028-e090c599 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_1a96371d-8788-a19e-a687-a935b5f2917a-e090c599,
#w-node-_1a96371d-8788-a19e-a687-a935b5f29190-e090c599,
#w-node-_1a96371d-8788-a19e-a687-a935b5f2919f-e090c599 {
  align-self: end;
}

#w-node-_1a96371d-8788-a19e-a687-a935b5f291a1-e090c599,
#w-node-_81fbf0a0-d522-9792-fa6e-932592ddf133-e090c599 {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-b086740a-6453-2282-5e13-7eae65a1f488-b45ce221,
#w-node-_5586ce51-3a77-fc5d-ab77-ce7bf620c902-b45ce221,
#w-node-e66f80ca-6b5d-1ff2-024e-d430453c81f9-b45ce221 {
  align-self: end;
}

#w-node-_272f86e5-c637-990a-af5b-9b940c9f414c-b45ce221 {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-dbdb231f-3721-ba01-b35b-fe8525f08cf5-b45ce221 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_8de50c2f-4414-9316-7bae-0b3c7a75c0a4-b45ce221 {
  grid-area: span 1 / span 4 / span 1 / span 4;
}

#w-node-_3cb4207e-aef5-b5ab-6667-e33bc628ab5d-b45ce221 {
  grid-area: span 1 / span 3 / span 1 / span 3;
}

#w-node-dd0b2880-ca5c-30d7-97f9-e9d606a5c1fb-b45ce221 {
  grid-area: span 1 / span 5 / span 1 / span 5;
}

#w-node-_8de50c2f-4414-9316-7bae-0b3c7a75c0b1-b45ce221 {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-_3b2fafe6-098a-7068-df95-95bf8d44ff90-b45ce221 {
  grid-area: span 1 / span 3 / span 1 / span 3;
}

#w-node-_3b2fafe6-098a-7068-df95-95bf8d44ff9d-b45ce221 {
  grid-area: span 1 / span 4 / span 1 / span 4;
}

#w-node-_3b2fafe6-098a-7068-df95-95bf8d44ffaa-b45ce221 {
  grid-area: span 1 / span 5 / span 1 / span 5;
}

#w-node-_3b2fafe6-098a-7068-df95-95bf8d44ffdb-b45ce221 {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

#w-node-_2bda0364-020c-dc46-9fb6-134667d5306a-b45ce221 {
  grid-area: span 1 / span 5 / span 1 / span 5;
}

#w-node-_2bda0364-020c-dc46-9fb6-134667d5309b-b45ce221 {
  grid-area: span 2 / span 2 / span 2 / span 2;
}

#w-node-_6ab104b9-9e8f-71a6-c9ef-3afbc8b2f88e-b45ce221 {
  grid-area: span 1 / span 5 / span 1 / span 5;
}

#w-node-a3568ff9-cc06-f5c1-3306-e3f4ff723fb4-b45ce221 {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

@media screen and (max-width: 991px) {
  #w-node-_272f86e5-c637-990a-af5b-9b940c9f414c-9d204e45 {
    grid-area: span 1 / span 1 / span 1 / span 1;
    place-self: center stretch;
  }

  #w-node-dbdb231f-3721-ba01-b35b-fe8525f08ce7-9d204e45,
  #w-node-dbdb231f-3721-ba01-b35b-fe8525f08cf5-9d204e45,
  #w-node-dbdb231f-3721-ba01-b35b-fe8525f08d03-9d204e45 {
    justify-self: start;
  }

  #w-node-_8d20d751-ba12-8f08-e28f-84feb73dd88b-b73dd887 {
    grid-area: span 1 / span 2 / span 1 / span 2;
  }

  #w-node-_3642eec2-12cd-7ce3-aa3d-f89ed0a9a2f5-d0a9a2ce {
    order: -9999;
  }

  #w-node-e54c84a8-78cd-4cd5-a2bb-6f9ac0882c6d-932f3934,
  #w-node-e54c84a8-78cd-4cd5-a2bb-6f9ac0882c7e-932f3934 {
    grid-area: 1 / 1 / 2 / 4;
  }

  #w-node-e54c84a8-78cd-4cd5-a2bb-6f9ac0882c84-932f3934 {
    grid-area: 2 / 1 / 3 / 4;
  }

  #w-node-e54c84a8-78cd-4cd5-a2bb-6f9ac0882c93-932f3934 {
    grid-area: 1 / 1 / 2 / 4;
  }

  #w-node-e54c84a8-78cd-4cd5-a2bb-6f9ac0882c99-932f3934 {
    grid-area: 2 / 1 / 3 / 4;
  }

  #w-node-e54c84a8-78cd-4cd5-a2bb-6f9ac0882ca8-932f3934 {
    grid-area: 1 / 1 / 2 / 4;
  }

  #w-node-e54c84a8-78cd-4cd5-a2bb-6f9ac0882cae-932f3934 {
    grid-area: 2 / 1 / 3 / 4;
  }

  #w-node-_9b7b5822-da24-24ea-531c-390fe3665b01-bda32aac {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }

  #w-node-_272f86e5-c637-990a-af5b-9b940c9f414c-b45ce221 {
    grid-area: span 1 / span 1 / span 1 / span 1;
    place-self: center stretch;
  }

  #w-node-dbdb231f-3721-ba01-b35b-fe8525f08ce7-b45ce221,
  #w-node-dbdb231f-3721-ba01-b35b-fe8525f08cf5-b45ce221,
  #w-node-dbdb231f-3721-ba01-b35b-fe8525f08d03-b45ce221 {
    justify-self: start;
  }
}

@media screen and (max-width: 767px) {
  #w-node-_272f86e5-c637-990a-af5b-9b940c9f414c-9d204e45 {
    place-self: center;
  }

  #w-node-_8d20d751-ba12-8f08-e28f-84feb73dd88b-b73dd887 {
    grid-column: span 1 / span 1;
  }

  #w-node-_8d20d751-ba12-8f08-e28f-84feb73dd8c8-b73dd887 {
    justify-self: center;
  }

  #w-node-_8d20d751-ba12-8f08-e28f-84feb73dd8f2-b73dd887 {
    grid-area: span 1 / span 2 / span 1 / span 2;
    place-self: center;
  }

  #w-node-e54c84a8-78cd-4cd5-a2bb-6f9ac0882c7e-932f3934 {
    grid-area: 1 / 1 / 2 / 4;
  }

  #w-node-e54c84a8-78cd-4cd5-a2bb-6f9ac0882c84-932f3934 {
    grid-area: 2 / 1 / 3 / 4;
  }

  #w-node-e54c84a8-78cd-4cd5-a2bb-6f9ac0882c93-932f3934 {
    grid-area: 1 / 1 / 2 / 4;
  }

  #w-node-e54c84a8-78cd-4cd5-a2bb-6f9ac0882c99-932f3934 {
    grid-area: 2 / 1 / 3 / 4;
  }

  #w-node-e54c84a8-78cd-4cd5-a2bb-6f9ac0882ca8-932f3934 {
    grid-area: 1 / 1 / 2 / 4;
  }

  #w-node-e54c84a8-78cd-4cd5-a2bb-6f9ac0882cae-932f3934 {
    grid-area: 2 / 1 / 3 / 4;
  }

  #w-node-_315b066c-9375-5d43-64fe-99c0825fb648-bda32aac {
    align-self: end;
  }

  #w-node-_9b7b5822-da24-24ea-531c-390fe3665b01-bda32aac,
  #w-node-_272f86e5-c637-990a-af5b-9b940c9f414c-b45ce221 {
    place-self: center;
  }
}

@media screen and (max-width: 479px) {
  #w-node-_8d20d751-ba12-8f08-e28f-84feb73dd88b-b73dd887 {
    justify-self: center;
  }

  #w-node-_1a96371d-8788-a19e-a687-a935b5f29199-e090c599 {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }

  #w-node-_1a96371d-8788-a19e-a687-a935b5f2919f-e090c599 {
    grid-area: span 1 / span 1 / span 1 / span 1;
    align-self: end;
  }

  #w-node-_1a96371d-8788-a19e-a687-a935b5f291a1-e090c599 {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }
}


@font-face {
  font-family: 'Fa 400';
  src: url('../fonts/fa-regular-400.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Fa Brands 400';
  src: url('../fonts/fa-brands-400.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Fa Solid 900';
  src: url('../fonts/fa-solid-900.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


.flipcard-wrapper {
  transition: transform 0.5s ease;
  transform-style: preserve-3d;
}

.flipcard-wrapper.flipped {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(180deg) rotateZ(0deg) skew(0deg, 0deg);
}

.flipcard-wrapper {
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
}

.content-carousel {
  white-space: nowrap;
  will-change: transform;
  animation: move-left 33s linear infinite;
  /* manipulate the speed of the marquee by changing "40s" line above*/
}

@keyframes move-left {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-50%);
  }
}

.w-dropdown-toggle {
  white-space: normal !important;
}

.timeline-container {
  border-radius: 8px;
  max-width: 90%;
  margin: 0 auto;
}

.timeline-description {
  z-index: 1;
  color: var(--dark-blue);
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2;
  position: relative;
  margin-bottom: 20px;
}

.timeline-list {
  list-style: none;
  padding: 0;
  position: relative;
  margin-left: 20px;
}

.timeline-list::before {
  content: '';
  position: absolute;
  left: -8.6px;
  top: 0;
  bottom: 0;
  border-left: 1.8px dashed #8c8679;
  /* Updated color for dashed line */
}

.timeline-item {
  margin-bottom: 20px;
  padding-left: 20px;
  position: relative;
}

.timeline-item::before {
  content: '';
  position: absolute;
  left: -12px;
  top: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #8c8679;
}

.timeline-text {
  font-size: 16px;
  margin: 0;
}

.one-grid-system {
  grid-template-columns: auto;
}

.one-grid-system .limited-heading-set {
  width: 100%;
  max-width: 100%;
}

#startup-ecosystem .heading-wrap {
  margin-bottom: 0;
}

.circle-container {
  display: flex;
  flex-wrap: wrap;
  /* Satır sarmalanmasını sağlar */
  justify-content: center;
  /* Yatayda ortalama */
  gap: 20px;
  /* Elemanlar arasındaki boşluk */
  max-width: 1024px;
  /* Konteyner genişliği */
  margin: 0 auto;
  /* Ortalamak için */
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  background-color: #f4a21c;
  color: white;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.circle:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}



.subscribe-modal.modal-container-new.first-layer {
  padding: 50px 62px;
  max-width: 700px;
}

.subscribe-modal .modal-description {
  font-size: 0.755rem;
  color: #555;
  margin-bottom: 1.5rem;
  line-height: 15px;
}

.subscribe-modal .form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.subscribe-modal .a-filter-search-text-field.jetboost-list-search-input-xr5q {
  font-size: 15px;
}

.subscribe-modal .form-note {
  letter-spacing: 2px;
  font-size: 12px;
  color: #9d9d9d;
  margin-top: -10px;
}

.subscribe-modal .policy-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  margin: 12px 0;
}

.subscribe-modal .policy-wrapper .checkbox-label {
  display: flex;
  align-items: center;
  position: relative;
}

.subscribe-modal .policy-container {
  font-size: 14px;
  color: #2c4050;
  letter-spacing: 1px;
  cursor: pointer;
  font-weight: 400;
  margin-left: 10px;
}

.subscribe-modal .policy-container .policy-label{
  text-decoration: underline;
  text-underline-offset: 2px;
}

.subscribe-modal .policy-container .privacy-policy-label{
  display: block;
}

.subscribe-modal .w-checkbox-input {
  cursor: pointer;
  background-color: #fff;
  border-style: none;
  border-radius: 4px;
  flex: none;
  width: 19px;
  height: 19px;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 20px;

}

.subscribe-modal .a-filter-checkbox.w--redirected-checked {
  background-color: var(--turqoise);
  background-image: url(/images/check-primary.svg);
  background-size: 16px 16px;
  border-style: none;
}

.subscribe-modal #privacy-policy {
  opacity: 0;
  position: absolute;
  z-index: 8;
  top: 3px;
  width: 20px;
  height: 20px;
}


.policy-content {
  position: relative;
  background-color: #fff;
  /* Açık mavi arka plan */
  border: 1px solid #d0e6e9;
  /* Hafif mavi çerçeve */
  border-radius: 10px;
  /* Yuvarlatılmış köşeler */
  padding: 20px;
  /* İç boşluk */
  font-size: 13px;
  /* Metin boyutu */
  color: #4d5e65;
  /* Metin rengi */
  line-height: 1.5;
  /* Satır aralığı */
}



/* Kapatma Butonu */
.policy-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  background-color: #cfe1e1;
  /* Buton arka plan rengi */
  color: #66b6bf;
  /* X işareti rengi */
  border: none;
  border-radius: 50%;
  /* Yuvarlak buton */
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  line-height: 24px;
  transition: background-color 0.2s ease;
}

.policy-close:hover {
  background-color: #5ea3b0;
  /* Hover efekti */
  color: #fff;
}

/* Metin Stili */
.policy-text {
  margin: 0;
  margin-top: 25px;
  max-height: 150px;
  /* Maksimum yükseklik */
  overflow-y: auto;
  /* İçerik fazla olursa dikey kaydırma */
}

/* Scrollbar Stili */
.policy-text::-webkit-scrollbar {
  width: 6px;
  /* Scrollbar genişliği */
}

.policy-text::-webkit-scrollbar-track {
  background: #afbac1;
  /* Scrollbar arkaplan rengi */
  border-radius: 10px;
}

.policy-text::-webkit-scrollbar-thumb {
  background-color: #506878;
  /* Scrollbar rengi */
  border-radius: 10px;
}

.policy-text::-webkit-scrollbar-thumb:hover {
  background-color: #084962;
  /* Hover rengi */
}


.subscribe-modal .button-left-icon {
  cursor: pointer;
  font-size: 14px;
  height: 39px;
}

.form-submit:disabled {
  background: #aaa;
  cursor: not-allowed;
}

.subscribe-modal .success-message {
  color: #66b6bf;
  margin-top: 16px;
  background-color: #fff;
  padding: 10px;
}

.error-message {
  color: #dc3545;
  margin-top: 16px;
  background-color: #fff;
  padding: 10px;
}

.subscribe-modal .modal-close-button {
  position: absolute;
  top: 12px;
  right: 12px;
  background-color: #66b6bf;
  border: none;
  font-size: 13px;
  color: #fff;
  cursor: pointer;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}

.subscribe-modal .modal-close-button .x-button {
  font-size: inherit;
  position: absolute;
  top: 3px;
  left: 8px;
}

@media (max-width: 768px) {
  .subscribe-modal.modal-container-new.first-layer {
    padding: 16px;
    max-width: 700px;
    width: 90%;
  }

  .subscribe-modal .form-note {
    letter-spacing: 1px;
  }

  .form-input {
    font-size: 0.875rem;
  }
}

#fdi-inflows .bento-card-header,
#fdi-inflows .bento-card-header .wrap-v-small {
  height: 100%;
}

#fdi-inflows .bento-card-header .wrap-v-small .image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

#fdi-inflows .bento-card-header .wrap-v-small .image-container .bento-card-image {
  padding: 0;
}

#fdi-inflows{
  padding-bottom: 0;
}