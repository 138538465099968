body.modal-open {
    overflow: hidden;
}

/* Overlay styles */
.rf-digitalmat-overlay {
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;

    align-items: flex-start;
    bottom: 0;
    box-sizing: border-box;
    display: flex;
    inset-inline-end: 0;
    inset-inline-start: 0;
    overflow: auto;
    padding: 54px 0;
    position: fixed;
    top: 0;
    z-index: 99999;
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    backface-visibility: visible;
    /* background: #0000007a; */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* Overlay content */
.rf-digitalmat-overlay-content {
    background: #ffffff;
    border-radius: 18px;
    padding: 20px;
    max-width: 800px;
    width: 100%;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
    position: relative;
}

/* Close button */
.rf-digitalmat-overlay-close {
    position: absolute;
    top: 15px;
    right: 5px;
    border: none;
    font-size: 24px;

    align-self: flex-end;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-inline-end: 20px;
    order: -1;
    z-index: 1;
    align-items: center;
    background: var(--turqoise);
    border-radius: 50%;
    color: #fff;
    height: 36px;
    outline: none;
    transition: color .1s linear, background .1s linear;
    width: 36px;
}

/* Header */
.rf-digitalmat-overlay-header h2 {
    font-family: 'SF Pro Text', Helvetica, Arial, sans-serif;
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    padding-bottom: 10px;
    color: #1d1d1f;
}

/* Gallery section */
.rf-digitalmat-gallery {
    padding: 50px 0;
}

.rf-digitalmat-gallery-items {
    width: 100%;
}

.rf-digitalmat-cardgallery-img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

/* Content section */
.rf-digitalmat-contentsection {
    padding: 20px;
}

.rf-digitalmat-featuretext-desc {
    font-size: 16px;
    font-weight: 400;
    color: #333;
    line-height: 1.5;
    margin-bottom: 20px;
}

/* Price and button section */
.rf-digitalmat-price-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rf-digitalmat-price {
    font-family: 'SF Pro Text', Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: #1d1d1f;
}

.rf-digitalmat-button {
    background-color: #0071e3;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.rf-digitalmat-button:hover {
    background-color: #005bb5;
}

/* Footer section */
.rf-digitalmat-overlay-footer {
    font-size: 12px;
    color: #6e6e73;
    padding: 10px;
    text-align: center;
}

/* Responsive adjustments */
@media (max-width: 1023px) {
    .rf-digitalmat-overlay-content {
        padding: 20px;
        width: 90%;
    }

    .rf-digitalmat-gallery-items {
        width: 100%;
    }

    .rf-digitalmat-price-button {
        flex-direction: column;
        align-items: center;
    }

    .rf-digitalmat-price {
        padding: 10px 0;
        text-align: center;
    }

    .rf-digitalmat-button {
        margin-top: 10px;
    }
}


@media(max-width: 1023px)and (max-device-width:736px) {
    .rf-digitalmat-overlay-content {
        min-height:100vh;
        min-width: 320px;
        width: 100%
    }
}

@media(max-width: 1023px) and (max-device-width: 736px) {
    .rf-digitalmat-overlay-content {
        padding-inline:6.25%
    }

    .rf-digitalmat-overlay-content {
        padding: 0
    }

    .rf-digitalmat-overlay-content {
        border-radius: 18px 18px 0 0;
        margin-bottom: 0;
        margin-top: 20px;
        min-height: calc(100vh - 20px);
        padding:20px;
    }
}